import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { useNotifications } from 'services/snackbar';
import { Menu, MenuItemStatus, PostMenuItemParams } from 'types/menus.types';

const postMenuItem = async (options: PostMenuItemParams) => {
  const { data } = await apiService.postMenuItem(options);
  return data;
};

const NEW_ID = 0;

export const useCreateMenuItem = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const createNewMenuItem = useCallback(
    ({ menuItem, menuId }: PostMenuItemParams) => ({
      id: NEW_ID as unknown as number,
      menuId,
      name: menuItem?.name || intl.formatMessage({ id: 'menus.items.label.untitled' }),
      section: menuItem?.section || '',
      description: menuItem?.description || '',
      isPurchasedItem: menuItem?.isPurchasedItem || false,
      status: menuItem?.status || MenuItemStatus.Unmatched,
      recipe: menuItem?.recipe || { id: null },
      purchasedItem: menuItem?.purchasedItem || { id: null },
      updatedAt: null,
    }),
    [intl],
  );

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (options: PostMenuItemParams) =>
      postMenuItem({
        menuId: options.menuId,
        menuItem: createNewMenuItem(options),
      }),
    onMutate: async (options) => {
      // Cancel any outgoing refetches so they don't overwrite our optimistic update
      await queryClient.cancelQueries({ queryKey: QueryKeys.menus.byId(options.menuId) });

      const queryData = queryClient.getQueriesData<ApiResponse<Menu>>({
        queryKey: QueryKeys.menus.byId(options.menuId),
        exact: true,
      });

      queryData.forEach(([key, data]) => {
        if (!!data) {
          queryClient.setQueryData<ApiResponse<Menu>>(key, {
            ...data,
            data: {
              ...data.data,
              menuItems: [...data.data.menuItems, createNewMenuItem(options)],
            },
          });
        }
      });

      return { previousData: queryData };
    },
    onSuccess: ({ data: newMenuItem }, options) => {
      const queryData = queryClient.getQueriesData<ApiResponse<Menu>>({
        queryKey: QueryKeys.menus.byId(options.menuId),
        exact: true,
      });

      queryData.forEach(([key, data]) => {
        if (!!data) {
          queryClient.setQueryData<ApiResponse<Menu>>(key, {
            ...data,
            data: {
              ...data.data,
              menuItems: data.data.menuItems.map((menuItem) =>
                menuItem.id === NEW_ID ? newMenuItem : menuItem,
              ),
            },
          });
        }
      });
    },
    onError: (_err, _, context) => {
      if (context) {
        // Optimistically update to the new value
        context.previousData.forEach(([key, data]) => {
          if (!!data) {
            queryClient.setQueryData(key, data);
          }
        });
      }

      notifications.error({ message: intl.formatMessage({ id: 'common.error_messages.error' }) });
    },
  });

  return {
    createMenuItem: mutateAsync,
    isPending,
  };
};
