import { Check as CheckIcon, RemoveCircle as RemoveCircleIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { ButtonWithMenu } from 'components/@common/ButtonWithMenu';

type Props = {
  isHQ: boolean;
  onPublish: () => void;
  onReject: () => void;
  isLoading: boolean;
};

const RecipeApprovedButton = ({ isHQ, onPublish, onReject, isLoading }: Props) => {
  if (isHQ) {
    return (
      <ButtonWithMenu
        buttonText={<FormattedMessage id="recipes.creator.button.hq.approved" />}
        testId="approved-recipe-menu-button"
        color="secondary"
        onClick={onPublish}
        loading={isLoading}
        startIcon={<CheckIcon />}
        menuItems={[
          {
            label: <FormattedMessage id="recipes.creator.button.hq.reject" />,
            icon: <RemoveCircleIcon />,
            onClick: onReject,
            action: 'reject',
          },
        ]}
      />
    );
  }
  return (
    <Button variant="contained" disabled data-testid="approve-recipe-button">
      <FormattedMessage id="recipes.creator.button.franchisee.approved" />
    </Button>
  );
};

export default RecipeApprovedButton;
