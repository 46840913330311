import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonInfiniteQueryOptions } from 'queries/types';
import { getInfiniteData } from 'queries/utils';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { GetRecipeOptions, RecipePreview } from 'types/recipes.types';

const fetchRecipes = async (options: GetRecipeOptions) => {
  const { data: recipes } = await apiService.getRecipes(options);
  return recipes;
};

export const useRecipes = (
  params: GetRecipeOptions,
  config?: CommonInfiniteQueryOptions<
    ApiResponse<RecipePreview[]>,
    ReturnType<typeof QueryKeys.recipes.all>,
    number
  >,
) => {
  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    isError,
    data,
    error,
  } = useInfiniteQuery({
    ...config,
    initialPageParam: 1,
    placeholderData: (previousData) => previousData, // keep previous data
    queryKey: QueryKeys.recipes.all(Object.values(params)),
    queryFn: ({ pageParam }) =>
      fetchRecipes({
        ...params,
        page: pageParam,
        language: params.language || DEFAULT_TRANSLATION_LOCALE,
      }),
    getNextPageParam: (lastPage, allPages) =>
      !!lastPage.links.next ? allPages.length + 1 : undefined,
    staleTime: config?.staleTime ?? Duration.FIVE_MIN,
  });

  const infiniteData = useMemo(() => getInfiniteData(data), [data]);

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    isError,
    error,
    ...infiniteData,
  };
};
