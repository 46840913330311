import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useTransformRecipe } from 'utils/recipe.utils';

import { useCalculateOnBrandness, useUpdateRecipe } from 'queries';
import { useNotifications } from 'services/snackbar';
import { SaveOptions } from 'types/common.types';
import { RecipeDetailed, RecipeFormValues, RecipeStatus } from 'types/recipes.types';

type RecipeFormProps = {
  initialRecipe: RecipeDetailed;
  disableActions?: boolean;
};

/**
 * Hook to handle the recipe form state and actions
 */
export const useRecipeForm = ({ initialRecipe, disableActions }: RecipeFormProps) => {
  const intl = useIntl();
  const notifications = useNotifications();
  const { transformFormRecipeToApi, transformApiRecipeToForm } = useTransformRecipe();

  const initialValues: RecipeFormValues = useMemo(() => {
    return transformApiRecipeToForm(initialRecipe);
  }, [transformApiRecipeToForm, initialRecipe]);

  const [isReadOnly, setIsReadOnly] = useState(
    disableActions || ![RecipeStatus.Draft, RecipeStatus.Rejected].includes(initialValues.status),
  );

  const methods = useForm<RecipeFormValues>({
    defaultValues: initialValues,
    progressive: true,
    mode: 'all',
  });

  const status = useWatch<RecipeFormValues>({
    name: 'status',
    control: methods.control,
  }) as RecipeFormValues['status'];

  useEffect(() => {
    if (isReadOnly) {
      methods.reset(initialValues);
    }
  }, [isReadOnly, methods, initialValues]);

  const { updateRecipe, isPending: isSavingRecipe, isError } = useUpdateRecipe();

  // we're not putting this in the form values because we don't want to set a form dirty state based on the `updatedAt` value
  const [lastSavedAt, setLastSavedAt] = useState(
    initialRecipe.updatedAt ? new Date(initialRecipe.updatedAt) : new Date(),
  );

  const isDraft = [RecipeStatus.Draft, RecipeStatus.Rejected].includes(status);

  useEffect(() => {
    if (!disableActions && isDraft) {
      setIsReadOnly(false);
    }
  }, [disableActions, isDraft]);

  const { calculateOnBrandness, isPending: isCalculating } = useCalculateOnBrandness();
  const handleCalculateOnBrandness = (successCallback?: () => void) => {
    calculateOnBrandness(initialRecipe.id, {
      onSuccess: ({ data }) => {
        methods.setValue('onBrandScore', Number(data.data.onBrand));
        successCallback?.();
      },
    });
  };

  const saveRecipe = ({ silent, onSuccess, onError }: SaveOptions) => {
    if (isSavingRecipe) return;
    updateRecipe(
      { ...transformFormRecipeToApi(methods.getValues()), silent },
      {
        onSuccess: () => {
          setLastSavedAt(new Date());
          onSuccess?.();
        },
        onError: (error) => {
          onError?.(error);
        },
      },
    );
  };

  // it can happen that while a new ingredient is being created, the autosave triggers. In this case we stop the autosave and try again in a couple of seconds
  const ingredients = useWatch<RecipeFormValues>({
    name: 'ingredients',
    control: methods.control,
  }) as RecipeFormValues['ingredients'];

  const blockSave = useMemo(
    () => ingredients.some((ingredient) => !ingredient.refId),
    [ingredients],
  );

  const handleSubmit = ({ silent, onSuccess, onError }: SaveOptions = { silent: false }) => {
    if (blockSave) return;
    methods.handleSubmit(
      () => saveRecipe({ silent, onSuccess, onError }),
      () =>
        notifications.error({
          message: intl.formatMessage(
            { id: 'general.actions.save.error' },
            { type: intl.formatMessage({ id: 'recipes.label.singular' }) },
          ),
        }),
    )();
  };

  const saveAndCalculate = () => {
    saveRecipe({ silent: true, onSuccess: handleCalculateOnBrandness });
  };

  return {
    initialValues,
    isReadOnly,
    setIsReadOnly,
    methods,
    handleSubmit,
    isSavingRecipe,
    saveAndCalculate,
    calculateOnBrandness: handleCalculateOnBrandness,
    isCalculating,
    status,
    isError,
    lastSavedAt,
    isDraft,
  };
};
