import { Stack } from '@mui/material';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  RecipeTranslationMethod,
  TranslatedRecipeFormIngredient,
} from 'types/recipe-translations.types';

import { TranslationField } from '../TranslationField';

type Props = {
  ingredient: TranslatedRecipeFormIngredient;
  index: number;
};

const IngredientTranslation = ({ index, ingredient }: Props) => {
  const { setValue } = useFormContext();
  const setMethodHuman = (fieldName: string) => {
    setValue(fieldName, RecipeTranslationMethod.Human);
  };

  return (
    <Stack gap={3}>
      <TranslationField
        fieldName={`translation.ingredients[${index}].translatedName`}
        initialValue={ingredient.translatedName}
        disabled={ingredient.isIngredientRecipe}
        method={ingredient.method}
        onChange={() => setMethodHuman(`translation.ingredients[${index}].method`)}
      />
      {!!ingredient.note.note && (
        <TranslationField
          fieldName={`translation.ingredients[${index}].note.translatedNote`}
          initialValue={ingredient.note.translatedNote}
          disabled={ingredient.isIngredientRecipe}
          method={ingredient.note.method}
          onChange={() => {
            setMethodHuman(`translation.ingredients[${index}].note.method`);
            setMethodHuman(
              `translation.ingredients[${index}].translation.fieldTranslationMethods.notes`,
            );
          }}
        />
      )}
    </Stack>
  );
};

export default memo(IngredientTranslation);
