import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useIsHQ } from 'hooks/useIsHQ';
import {
  useApproveRecipe,
  usePublishRecipe,
  useRejectRecipe,
  useSubmitRecipe,
  useTranslateRecipe,
} from 'queries/recipes';
import { useNotifications } from 'services/snackbar';
import { Translation } from 'types/recipe-translations.types';
import { RecipeFormValues, RecipeStatus } from 'types/recipes.types';

import {
  RecipeApprovedButton,
  RecipeOverrideDialog,
  RecipePublishedButton,
  RecipeSubmitButton,
  RecipeSubmittedButton,
} from './components';
import RecipeCommentDialog from './RecipeCommentDialog';

interface Props {
  isSavingRecipe: boolean;
  onEditRecipe: () => void;
  onStopEditRecipe: () => void;
  handleStatusChange: (options: {
    successCallback?: () => void;
    skipValidation?: boolean;
  }) => Promise<void>;
  verifiedTranslations: Translation[];
}

const RecipeApprovalState = ({
  isSavingRecipe,
  onEditRecipe,
  onStopEditRecipe,
  handleStatusChange,
  verifiedTranslations = [],
}: Props) => {
  const posthog = usePostHog();
  const isHQ = useIsHQ();
  const { getValues, setValue } = useFormContext<RecipeFormValues>();
  const navigate = useNavigate();
  const intl = useIntl();
  const notifications = useNotifications();
  const status = useWatch<RecipeFormValues>({ name: 'status' }) as RecipeFormValues['status'];
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [isOverrideDialogOpen, setIsOverrideDialogOpen] = useState(false);

  const { submitRecipe, isPending: isSubmittingRecipe } = useSubmitRecipe();
  const { rejectRecipe, isPending: isRejectingRecipe } = useRejectRecipe();
  const { approveRecipe, isPending: isApprovingRecipe } = useApproveRecipe();
  const { publishRecipe, isPending: isPublishingRecipe } = usePublishRecipe();
  const { translateRecipe, isPending: isTranslatePending } = useTranslateRecipe();

  // we remove empty notes, ingredients, and preparation steps before changing status
  const cleanRecipe = () => {
    setValue(
      'notes',
      getValues('notes').filter((note) => !!note.note),
    );
    setValue(
      'ingredients',
      getValues('ingredients').filter((ingredient) => !!ingredient.name),
    );
    setValue(
      'preparationSteps',
      getValues('preparationSteps').filter((step) => step.image || step.description),
    );
  };

  const handleSubmitRecipe = (recipeId: number) => {
    submitRecipe(recipeId, {
      onSuccess: () => {
        posthog.capture(`recipe_status_change`, {
          recipe_id: recipeId,
          name: getValues('name'),
          status: RecipeStatus.Submitted,
        });
        setValue('status', RecipeStatus.Submitted, { shouldDirty: false });
        onStopEditRecipe();
        translateRecipe(recipeId);
      },
      onError: onEditRecipe,
    });
  };

  const handleApproveRecipe = (recipeId: number, isUnpublish?: boolean) => {
    approveRecipe(
      { recipeId, isUnpublish },
      {
        onSuccess: () => {
          onStopEditRecipe();
          posthog.capture(`recipe_status_change`, {
            recipe_id: recipeId,
            name: getValues('name'),
            status: RecipeStatus.Approved,
          });
          setValue('status', RecipeStatus.Approved, { shouldDirty: false });
        },
      },
    );
  };

  const handlePublishRecipe = (recipeId: number) => {
    publishRecipe(
      { silent: true, recipeId },
      {
        onSuccess: () => {
          // the user will be redirected back to the experimental kitchen here
          posthog.capture(`recipe_status_change`, {
            recipe_id: recipeId,
            name: getValues('name'),
            status: RecipeStatus.Published,
          });
          setValue('status', RecipeStatus.Published, { shouldDirty: false });
          navigate(Routes.ExperimentalKitchen);
          notifications.success({
            message: intl.formatMessage({ id: 'recipes.creator.published.success' }),
            cta: intl.formatMessage({ id: 'recipes.creator.published.success.cta' }),
            onClick: () => navigate(Routes.Cookbook),
          });
        },
      },
    );
  };

  const changeStatus = ({
    nextStatus,
    message = '',
    isUnpublish,
  }: {
    nextStatus: RecipeStatus;
    message?: string;
    isUnpublish?: boolean;
  }) => {
    const recipeId = getValues('id');

    cleanRecipe();

    switch (nextStatus) {
      case RecipeStatus.Draft:
        return;

      case RecipeStatus.Rejected:
        return rejectRecipe(
          { recipeId, message },
          {
            onSuccess: () => {
              return handleStatusChange({
                skipValidation: true,
                successCallback: () => {
                  posthog.capture(`recipe_status_change`, {
                    recipe_id: recipeId,
                    name: getValues('name'),
                    status: nextStatus,
                  });
                  setValue('status', nextStatus, { shouldDirty: false });
                  onEditRecipe();
                  setIsRejectDialogOpen(false);
                },
              });
            },
          },
        );

      case RecipeStatus.Submitted:
        if (
          !!verifiedTranslations.length &&
          [RecipeStatus.Rejected, RecipeStatus.Draft].includes(status)
        ) {
          setIsOverrideDialogOpen(true);
        } else {
          handleStatusChange({ successCallback: () => handleSubmitRecipe(recipeId) });
        }
        return;

      case RecipeStatus.Approved:
        if (isUnpublish) {
          handleApproveRecipe(recipeId, isUnpublish);
        } else {
          handleStatusChange({
            successCallback: () => handleApproveRecipe(recipeId, isUnpublish),
          });
        }
        return;

      case RecipeStatus.Published:
        handleStatusChange({
          successCallback: () => {
            onStopEditRecipe();
            handlePublishRecipe(recipeId);
          },
        });
        return;

      case RecipeStatus.Archived:
      // not implemented yet
      default:
        return;
    }
  };

  const isLoadingAny =
    isSavingRecipe ||
    isSubmittingRecipe ||
    isApprovingRecipe ||
    isRejectingRecipe ||
    isPublishingRecipe ||
    isTranslatePending;

  const ApprovalButton = () => {
    if (status === RecipeStatus.Archived) return null;
    if ([RecipeStatus.Draft, RecipeStatus.Rejected].includes(status)) {
      return (
        <RecipeSubmitButton
          isSaving={isSavingRecipe}
          isSubmitting={isSubmittingRecipe}
          onSubmit={() => changeStatus({ nextStatus: RecipeStatus.Submitted })}
          status={status}
        />
      );
    }
    if (status === RecipeStatus.Submitted) {
      return (
        <RecipeSubmittedButton
          isHQ={isHQ}
          isLoading={isLoadingAny}
          onPublish={() => changeStatus({ nextStatus: RecipeStatus.Published })}
          onApprove={() => changeStatus({ nextStatus: RecipeStatus.Approved })}
          onReject={() => setIsRejectDialogOpen(true)}
        />
      );
    }

    if (status === RecipeStatus.Approved) {
      return (
        <RecipeApprovedButton
          isHQ={isHQ}
          isLoading={isLoadingAny}
          onPublish={() => changeStatus({ nextStatus: RecipeStatus.Published })}
          onReject={() => setIsRejectDialogOpen(true)}
        />
      );
    }

    if (status === RecipeStatus.Published) {
      return (
        <RecipePublishedButton
          isHQ={isHQ}
          isLoading={isLoadingAny}
          onUnpublish={() => changeStatus({ nextStatus: RecipeStatus.Approved, isUnpublish: true })}
        />
      );
    }
  };

  return (
    <>
      <RecipeCommentDialog
        title="recipes.creator.reject.dialog.title"
        isLoading={isLoadingAny}
        addComment={(message) => changeStatus({ nextStatus: RecipeStatus.Rejected, message })}
        open={isRejectDialogOpen}
        onClose={() => setIsRejectDialogOpen(false)}
      />
      <RecipeOverrideDialog
        isOpen={isOverrideDialogOpen}
        onConfirm={() => {
          const recipeId = getValues('id');
          if ([RecipeStatus.Rejected, RecipeStatus.Draft].includes(status)) {
            handleStatusChange({ successCallback: () => handleSubmitRecipe(recipeId) });
          }
        }}
        onClose={() => setIsOverrideDialogOpen(false)}
        translationsAmount={verifiedTranslations.length}
      />
      <ApprovalButton />
    </>
  );
};

export default RecipeApprovalState;
