import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { PostRecipeParams } from 'types/recipes.types';

const postRecipe = async (options: PostRecipeParams) => {
  const { data: recipe } = await apiService.postRecipe(options);
  return recipe;
};

export const useCreateRecipe = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: postRecipe,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: QueryKeys.recipes.all() }),
    onError: () => {
      notifications.error({ message: intl.formatMessage({ id: 'common.error_messages.error' }) });
    },
  });

  return {
    createRecipe: mutateAsync,
    isPending,
  };
};
