import { useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';

const exportRecipe = async (recipeId: number) => {
  const { data } = await apiService.exportRecipe(recipeId);
  return data.data;
};

export const useExportRecipe = () => {
  const intl = useIntl();
  const notifications = useNotifications();

  const { mutate, isPending } = useMutation({
    mutationFn: exportRecipe,
    onMutate: () => {
      notifications.info({ message: intl.formatMessage({ id: 'recipes.actions.print.pending' }) });
    },
    onError: () => {
      notifications.error({ message: intl.formatMessage({ id: 'error.general.text' }) });
    },
  });

  return {
    exportRecipe: mutate,
    isPending,
  };
};
