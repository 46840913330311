import { Stack, TableRow, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { RecipeTranslationMethod, TranslatedNote } from 'types/recipe-translations.types';

import { TranslationField } from '../TranslationField';
import { TableCell, TranslationsTable } from '../TranslationsTable';

type Props = {
  targetLocale: string;
  isLoading?: boolean;
};

const RemarksTranslationsTable = ({
  targetLocale = DEFAULT_TRANSLATION_LOCALE,
  isLoading,
}: Props) => {
  const { setValue } = useFormContext();
  const remarks = useWatch({
    name: `translation.notes`,
  }) as TranslatedNote[];

  return (
    <TranslationsTable isLoading={isLoading} targetLocale={targetLocale}>
      {remarks?.map((remark, index) => (
        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell>
            <Stack gap={1}>
              <Typography variant="body1">{remark.note}</Typography>
            </Stack>
          </TableCell>
          <TableCell>
            <TranslationField
              fieldName={`translation.notes[${index}].translation.note`}
              initialValue={remark.translation.note}
              method={remark.translation.translationMethod}
              onChange={() => {
                setValue(
                  `translation.notes[${index}].translation.translationMethod`,
                  RecipeTranslationMethod.Human,
                );
              }}
            />
          </TableCell>
        </TableRow>
      ))}
    </TranslationsTable>
  );
};

export default RemarksTranslationsTable;
