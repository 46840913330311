import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { Routes } from 'constants/routes.constants';
import { useMedia } from 'hooks';
import { RecipeDetailed, RecipeOnBrandnessRuleSet, RecipeStatus } from 'types/recipes.types';

import ErrorBoundary from 'components/@boundaries/ErrorBoundary';
import { BackButton, ConfirmationDialog } from 'components/@common';
import { RecipeActions } from 'components/@recipe-form/RecipeForm/RecipeFormMainInfo/components';
import { AutosaveState } from 'components/@states';
import { CollapseFade } from 'components/@utils';

import { useRecipeForm, useRecipeTranslationForm } from './hooks';
import { RecipeFormItems } from './RecipeFormItems';
import { RecipeFormMainInfo } from './RecipeFormMainInfo';
import { RecipeFormPerformance } from './RecipeFormPerformance';

type Props = {
  initialRecipe: RecipeDetailed;
  disableActions?: boolean;
};

const RecipeForm = ({ initialRecipe, disableActions }: Props) => {
  const {
    setIsReadOnly,
    isReadOnly,
    saveAndCalculate,
    isSavingRecipe,
    calculateOnBrandness,
    isCalculating,
    handleSubmit,
    methods: formMethods,
    isError,
    initialValues,
    status,
    lastSavedAt,
    isDraft,
  } = useRecipeForm({ initialRecipe, disableActions });

  const {
    isLoadingTranslations,
    handleSubmit: submitTranslations,
    translationLocale,
    isEditingTranslations,
    toggleEditTranslations,
    setTranslationLocale,
  } = useRecipeTranslationForm({
    recipeId: initialValues.id,
    formMethods,
  });

  const [supported, setSupported] = useState(true);
  const { sm } = useMedia();
  useEffect(() => setSupported((!sm && !isReadOnly) || isReadOnly), [sm, isReadOnly]);

  const overviewRoute =
    status === RecipeStatus.Published ? Routes.Cookbook : Routes.ExperimentalKitchen;

  const handleEditRecipe = () => {
    setTranslationLocale(DEFAULT_TRANSLATION_LOCALE);
    setIsReadOnly(false);
  };
  const handleEditTranslations = () => {
    isEditingTranslations ? submitTranslations() : toggleEditTranslations();
  };

  return (
    <ErrorBoundary boundary="recipe-form">
      <FormProvider {...formMethods}>
        <CollapseFade in sx={{ bgcolor: 'transparent', m: 0 }} timeout={600}>
          <Box display="flex" flexDirection="column" gap={3} height="100%" width="100%">
            {!disableActions && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className="no-print"
              >
                <BackButton to={overviewRoute} />

                {!isReadOnly && isDraft && (
                  <AutosaveState
                    isError={isError}
                    isPending={isSavingRecipe}
                    savedAt={lastSavedAt}
                    handleSave={(callback) => handleSubmit({ silent: true, onSuccess: callback })}
                  />
                )}
              </Box>
            )}

            <Stack component="form">
              <RecipeFormMainInfo
                recipeTranslations={initialRecipe.translations}
                isSaving={isSavingRecipe || isCalculating}
                isReadOnly={isReadOnly}
                disableActions={disableActions}
                onEditRecipe={handleEditRecipe}
                onStopEditRecipe={() => setIsReadOnly(true)}
                onSave={handleSubmit}
                calculateOnBrandness={calculateOnBrandness}
                onEditTranslations={handleEditTranslations}
                isEditingTranslations={isEditingTranslations}
                targetTranslationLocale={translationLocale}
                isLoadingTranslations={isLoadingTranslations}
              />

              <RecipeFormPerformance
                isCalculating={isCalculating}
                disableActions={disableActions}
                onBrandnessErrors={initialRecipe.onBrandErrors as RecipeOnBrandnessRuleSet[]}
                isReadOnly={isReadOnly}
                onCalculate={saveAndCalculate}
                isEditingTranslations={isEditingTranslations}
              />

              <RecipeFormItems
                initialValues={initialValues}
                isReadOnly={isReadOnly}
                isEditingTranslations={isEditingTranslations}
                isLoadingTranslations={isLoadingTranslations}
                targetTranslationLocale={translationLocale}
              />

              {!disableActions && (
                <Box mb={9} mt={12} display="flex" justifyContent="flex-end" gap={3}>
                  <RecipeActions
                    isReadOnly={isReadOnly}
                    recipeTranslations={initialRecipe.translations}
                    isSaving={isSavingRecipe || isCalculating}
                    onEditRecipe={handleEditRecipe}
                    onStopEditRecipe={() => setIsReadOnly(true)}
                    saveRecipe={handleSubmit}
                    calculateOnBrandness={calculateOnBrandness}
                    isEditingTranslations={isEditingTranslations}
                    onEditTranslations={handleEditTranslations}
                  />
                </Box>
              )}
            </Stack>
          </Box>
        </CollapseFade>
      </FormProvider>

      <ConfirmationDialog
        open={!supported}
        title={<FormattedMessage id="error.mobile.not_supported.title" />}
        message={<FormattedMessage id="error.mobile.not_supported.text" />}
        onConfirm={() => history.back()}
        onClose={() => setSupported(true)}
        closeText={<FormattedMessage id="error.mobile.not_supported.proceed" />}
        confirmText={<FormattedMessage id="error.mobile.not_supported.go_back" />}
      />
    </ErrorBoundary>
  );
};

export default RecipeForm;
