import { Box, outlinedInputClasses, Skeleton, TextField, Typography } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { RecipeFormValues } from 'types/recipes.types';

type Props = {
  index: number;
  isReadOnly: boolean;
  isShowingTranslation: boolean;
  isLoadingTranslation: boolean;
};

const RemarkItem = ({ index, isReadOnly, isShowingTranslation, isLoadingTranslation }: Props) => {
  const intl = useIntl();
  const { control } = useFormContext<RecipeFormValues>();

  const translatedValue = useWatch({
    name: `translation.notes[${index}].translation.note`,
  });

  return (
    <Box flex={1}>
      <Controller
        control={control}
        name={`notes.${index}.note`}
        render={({ field: { onChange, value } }) => {
          if (isLoadingTranslation) {
            return <Skeleton height={16} width="50%" />;
          }

          if (isReadOnly) {
            return (
              <Typography
                variant="body2"
                color="black.90"
                whiteSpace="pre-wrap"
                textOverflow="revert"
                maxWidth="100%"
                sx={{
                  lineBreak: 'loose',
                  wordBreak: 'break-word',
                }}
              >
                {isShowingTranslation ? translatedValue : value || '-'}
              </Typography>
            );
          }

          return (
            <TextField
              value={value}
              onChange={onChange}
              placeholder={intl.formatMessage({ id: 'recipes.remarks.description' })}
              size="small"
              multiline
              variant="outlined"
              sx={{
                [`& .${outlinedInputClasses.input}`]: {
                  py: 1,
                  px: 1.75,
                },
              }}
              inputProps={{
                maxLength: 255,
                'data-testid': `stockage-remark${index + 1}`,
              }}
            />
          );
        }}
      />
    </Box>
  );
};

export default RemarkItem;
