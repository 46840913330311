import { AxiosInstance } from 'axios';

import chatApi from './chat.api';
import insightsApi from './insights.api';
import languagesApi from './languages.api';
import mediaApi from './media.api';
import menuApi from './menu.api';
import recipeApi from './recipe.api';
import userApi from './user.api';

interface Options {
  api: AxiosInstance;
}

class RestApiService {
  public api: AxiosInstance;

  constructor(options: Options) {
    this.api = options.api;
  }

  // USER
  public getUser = userApi.getUser;

  // RECIPE
  public getRecipe = recipeApi.getRecipe;
  public getRecipeComplianceRules = recipeApi.getRecipeComplianceRules;
  public exportRecipe = recipeApi.exportRecipe;
  public getRecipes = recipeApi.getRecipes;
  public postRecipe = recipeApi.postRecipe;
  public putRecipe = recipeApi.putRecipe;
  public calculateOnBrandness = recipeApi.calculateOnBrandness;
  public putPerformanceData = recipeApi.putPerformanceData;

  // RECIPE TRANSLATIONS
  public translateRecipe = recipeApi.translateRecipe;
  public getRecipeTranslations = recipeApi.getRecipeTranslations;
  public putRecipeTranslations = recipeApi.putRecipeTranslations;

  // RECIPE APPROVAL
  public submitRecipe = recipeApi.submitRecipe;
  public archiveRecipe = recipeApi.archiveRecipe;
  public approveRecipe = recipeApi.approveRecipe;
  public rejectRecipe = recipeApi.rejectRecipe;
  public publishRecipe = recipeApi.publishRecipe;
  public addComment = recipeApi.addComment;

  // MEASUREMENT
  public getMeasurements = recipeApi.getMeasurements;
  public postMeasurement = recipeApi.postMeasurement;

  // INGREDIENT
  public getIngredients = recipeApi.getIngredients;
  public postIngredient = recipeApi.postIngredient;

  // MENU
  public getMenus = menuApi.getMenus;
  public getMenu = menuApi.getMenu;
  public postMenu = menuApi.postMenu;
  public getMenuStatus = menuApi.getMenuStatus;
  public putMenu = menuApi.putMenu;
  public deleteMenu = menuApi.deleteMenu;
  public exportMenu = menuApi.exportMenu;
  public getRecommendedRecipes = menuApi.getRecommendedRecipes;
  public getMenuComplianceRules = menuApi.getMenuComplianceRules;

  // MENU ITEM
  public postMenuItem = menuApi.postMenuItem;
  public putMenuItem = menuApi.putMenuItem;
  public putMenuItems = menuApi.putMenuItems;
  public deleteMenuItem = menuApi.deleteMenuItem;
  public recheckMenuItemMatches = menuApi.recheckMenuItemMatches;

  // MEDIA
  public uploadMedia = mediaApi.uploadMedia;

  // CHAT
  public getChat = chatApi.getChat;
  public sendChatMessage = chatApi.sendChatMessage;

  // INSIGHTS
  public getInsights = insightsApi.getInsights;

  // LANGUAGES
  public getLanguages = languagesApi.getLanguages;
}

export default RestApiService;
