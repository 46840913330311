import { Publish as PublishIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { FormattedMessage } from 'react-intl';

import { RecipeStatus } from 'types/recipes.types';

type Props = {
  isSubmitting: boolean;
  isSaving: boolean;
  onSubmit: () => void;
  status: RecipeStatus;
};

const RecipeSubmitButton = ({ isSubmitting, isSaving, onSubmit, status }: Props) => {
  return (
    <LoadingButton
      data-testid="submit-recipe-button"
      loading={isSubmitting}
      disabled={isSaving}
      variant="contained"
      color="info"
      sx={{ px: 3 }}
      onClick={onSubmit}
    >
      <PublishIcon fontSize="small" />

      <FormattedMessage
        id={
          status === RecipeStatus.Rejected
            ? 'recipes.creator.button.franchisee.rejected'
            : 'recipes.creator.button.franchisee.draft'
        }
      />
    </LoadingButton>
  );
};

export default RecipeSubmitButton;
