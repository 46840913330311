import { Close as CloseIcon, InfoRounded as InfoRoundedIcon } from '@mui/icons-material';
import {
  Alert,
  alertClasses,
  Box,
  Button,
  Collapse,
  IconButton,
  Stack,
  SxProps,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  type?: 'info' | 'warning';
  children: ReactNode;
  actionLabel?: ReactNode;
  onAction?: () => void;
  arrow?: boolean;
  sx?: SxProps;
};

const InfoAlert = ({
  isOpen,
  onClose,
  type = 'info',
  children,
  actionLabel,
  onAction,
  arrow,
  sx,
}: Props) => {
  const theme = useTheme();
  const color = {
    light: theme.palette[type].light,
    main: theme.palette[type].main,
  };

  return (
    <Collapse
      unmountOnExit
      mountOnEnter
      in={isOpen}
      className="no-print"
      sx={{
        position: 'relative',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: 0,
      }}
    >
      {arrow && (
        <Box
          sx={{
            ml: 'calc(100% / 8)',
            display: 'flex',
            alignItems: 'end',
            ['& path']: { fill: theme.palette[type].light },
            ['& svg']: { width: 44, height: 13, fill: color.light },
          }}
        >
          <svg viewBox="0 0 44 13" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4486 0.907024C18.2489 -0.0767679 19.7511 -0.0767677 20.5514 0.907024L43.1929 28.7378C44.256 30.0447 43.3261 32 41.6415 32H-3.64146C-5.32611 32 -6.25604 30.0447 -5.1929 28.7378L17.4486 0.907024Z" />
          </svg>
        </Box>
      )}
      <Alert
        variant="filled"
        sx={{ bgcolor: color.light, [`& .${alertClasses.action}`]: { paddingTop: 0 }, ...sx }}
        icon={<InfoRoundedIcon sx={{ color: color.main, width: 24 }} />}
        action={
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
            height="100%"
          >
            {onAction && actionLabel && (
              <Button onClick={onAction} variant="text" color="info">
                {actionLabel}
              </Button>
            )}
            {onClose && (
              <IconButton color="inherit" size="small" onClick={() => onClose?.()}>
                <CloseIcon fontSize="inherit" sx={{ color: color.main }} />
              </IconButton>
            )}
          </Stack>
        }
      >
        {children}
      </Alert>
    </Collapse>
  );
};

export default InfoAlert;
