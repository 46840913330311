import { UniqueIdentifier } from '@dnd-kit/core';
import {
  defaultAnimateLayoutChanges,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Delete as DeleteIcon, Reorder as ReorderIcon } from '@mui/icons-material';
import { Box, IconButton, ListItem } from '@mui/material';
import { createContext, memo, ReactNode, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';

import { DndContext } from '../sortable.types';

const SortableItemContext = createContext<DndContext>({
  attributes: null,
  listeners: undefined,
  ref() {},
});

type Props = {
  id: UniqueIdentifier;
  onRemove: (id: UniqueIdentifier) => void;
  children: ReactNode;
  isReadOnly?: boolean;
  withBottomBorder?: boolean;
};

const SortableListItem = ({
  id,
  onRemove,
  withBottomBorder = false,
  children,
  isReadOnly,
}: Props) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges: defaultAnimateLayoutChanges,
    strategy: verticalListSortingStrategy,
    transition: {
      duration: 200,
      easing: 'ease-out',
    },
    disabled: isReadOnly,
    resizeObserverConfig: {
      updateMeasurementsFor: ['width', 'height'],
    },
  });

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );

  return (
    <SortableItemContext.Provider value={context}>
      <CSSTransition key={id} nodeRef={setNodeRef} timeout={500}>
        <ListItem
          ref={setNodeRef}
          sx={(theme) => ({
            transition,
            transform: CSS.Translate.toString(transform),
            opacity: isDragging ? 0.2 : undefined,
            py: 0.5,
            userSelect: 'none',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            borderBottom: `1px solid`,
            borderColor: theme.palette.grey[400],
            '&:last-child': {
              borderBottom: withBottomBorder ? undefined : 'none',
            },
          })}
        >
          {!isReadOnly && (
            <IconButton
              {...attributes}
              {...listeners}
              sx={(theme) => ({
                cursor: isDragging ? 'grabbing' : 'grab',
                '&:focus, :hover': {
                  color: `${theme.palette.primary['dark']}`,
                },
              })}
              className="no-print"
            >
              <ReorderIcon sx={{ width: 24, height: 24 }} />
            </IconButton>
          )}

          <Box flex={1}>{children}</Box>

          {!isReadOnly && (
            <IconButton
              onClick={() => onRemove(id)}
              className="no-print"
              sx={(theme) => ({
                '&:focus, :hover': {
                  color: `${theme.palette.error['main']} !important`,
                },
              })}
            >
              <DeleteIcon sx={{ width: 24, height: 24 }} />
            </IconButton>
          )}
        </ListItem>
      </CSSTransition>
    </SortableItemContext.Provider>
  );
};
export default memo(SortableListItem);
