import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  menuItemClasses,
  outlinedInputClasses,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { getLanguageKey } from 'utils/recipe-translations.utils';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { useLanguages } from 'queries';
import { useTranslationLocale } from 'store';
import { RecipeTranslationStatus, Translation } from 'types/recipe-translations.types';

type Props = {
  disabled?: boolean;
  recipeTranslations?: Translation[];
  prefix?: ReactNode;
  suffix?: (lang: string, status?: RecipeTranslationStatus) => ReactNode;
  menuItemSuffix?: (lang: string, status: RecipeTranslationStatus) => ReactNode;
};

const TranslationSelector = ({
  disabled,
  recipeTranslations = [],
  prefix,
  suffix,
  menuItemSuffix,
}: Props) => {
  const { languages } = useLanguages();
  const { translationLocale, setTranslationLocale } = useTranslationLocale();

  if (!languages) return null;

  /* Sort languages by verified state */
  const mappedLanguages = languages
    .map((lang) => {
      const translation = recipeTranslations.find((t) => t.language === lang);
      //@TODO: For franchisees, show only their languages (to discuss)
      return {
        lang,
        state: translation?.status || RecipeTranslationStatus.Pending,
      };
    })
    .sort((a, b) => {
      if (a.lang === DEFAULT_TRANSLATION_LOCALE) return -1;
      if (b.lang === DEFAULT_TRANSLATION_LOCALE) return 1;
      const order = {
        [RecipeTranslationStatus.Verified]: 0,
        [RecipeTranslationStatus.AI]: 1,
        [RecipeTranslationStatus.Pending]: 2,
        [RecipeTranslationStatus.Error]: 3,
      };
      return order[a.state] - order[b.state];
    });

  return (
    <Select
      disabled={disabled}
      value={translationLocale}
      onChange={(e) => setTranslationLocale(e.target.value)}
      IconComponent={KeyboardArrowDown}
      MenuProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'right' },
        sx: { mt: 1.5 },
      }}
      slotProps={{
        root: {
          sx: (theme) => ({
            minWidth: 120,
            height: 40,
            pb: 0.25,
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.black[10],
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: theme.palette.black[10],
              borderWidth: 1,
            },
          }),
        },
      }}
      renderValue={(selected) => (
        <Stack direction="row" alignItems="center" gap={2}>
          {prefix}
          <Typography variant="button">
            <FormattedMessage id={getLanguageKey(selected)} />
          </Typography>
          {!!suffix && (
            <Box mr={1}>
              {suffix(selected, mappedLanguages.find((l) => l.lang === selected)?.state)}
            </Box>
          )}
        </Stack>
      )}
    >
      {mappedLanguages?.map(({ lang, state }) => (
        <MenuItem
          key={lang}
          value={lang}
          defaultValue={DEFAULT_TRANSLATION_LOCALE}
          selected={lang === translationLocale}
          disabled={disabled}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            // Correct override could not be found, hence !important
            [`&.${menuItemClasses.selected}`]: {
              backgroundColor: 'rgba(0, 0, 0, 0.05) !important',
            },
            [`&.${menuItemClasses.selected}:hover`]: {
              backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
            },
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
          }}
        >
          <ListItemText>
            <FormattedMessage id={getLanguageKey(lang)} />
          </ListItemText>

          {menuItemSuffix && (
            <ListItemIcon sx={{ ml: 2 }}>{menuItemSuffix(lang, state)}</ListItemIcon>
          )}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TranslationSelector;
