import { useMutation, useQueryClient } from '@tanstack/react-query';
import { capitalize } from 'lodash';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';

const archiveRecipe = async (id: number) => {
  const res = await apiService.archiveRecipe(id);
  return res;
};

export const useArchiveRecipe = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: archiveRecipe,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.recipes.all() });

      notifications.success({
        message: intl.formatMessage(
          { id: 'general.actions.archive.success' },
          {
            type: capitalize(intl.formatMessage({ id: 'recipes.label.singular' })),
          },
        ),
      });
    },
    onError: () => {
      notifications.error({
        message: intl.formatMessage(
          { id: 'general.actions.archive.error' },
          {
            type: capitalize(intl.formatMessage({ id: 'recipes.label.singular' })),
          },
        ),
      });
    },
  });

  return {
    deleteRecipe: mutate,
    isPending,
  };
};
