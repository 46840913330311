import { Close as CloseIcon } from '@mui/icons-material';
import {
  Autocomplete,
  autocompleteClasses,
  buttonBaseClasses,
  inputBaseClasses,
  inputClasses,
  outlinedInputClasses,
  TextField,
} from '@mui/material';
import { noop } from 'lodash';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { MEASUREMENTS_PER_PAGE } from 'constants/recipes.constants';
import { useSearchState } from 'hooks';
import { useMeasurements } from 'queries';
import { Measurement } from 'types/recipes.types';

import EmptyMeasurements from './EmptyMeasurements';
import MeasurementItem from './MeasurementItem';
import MeasurementItemSkeleton from './MeasurementItemSkeleton';

type Props = {
  value: Measurement | null;
  onChange: (newValue: Measurement | null) => void;
  testId?: string;
};

const MeasurementAutocomplete = ({ value, testId, onChange }: Props) => {
  const intl = useIntl();

  const { searchValue, debouncedSearchValue, handleSearchChange } = useSearchState();
  const [fetchEnabled, setFetchEnabled] = useState(false);

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    data: measurements,
  } = useMeasurements(
    { search: debouncedSearchValue.trim(), page_size: MEASUREMENTS_PER_PAGE, sort: 'unit' },
    {
      _optimisticResults: 'optimistic',
      enabled: fetchEnabled,
    },
  );

  const options = useMemo(() => {
    let filtered = measurements;
    if (searchValue.trim() !== '' && !measurements.length) {
      filtered = [{ unit: searchValue.trim(), id: 0 }];
    }
    // Filter out duplicates and items without a unit
    return [
      ...new Map(filtered.filter((item) => !!item.unit).map((item) => [item.unit, item])).values(),
    ];
  }, [measurements, searchValue]);

  const getNext = (inView: boolean) => {
    if (inView && hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const handleClear = () => onChange({ unit: '', id: 0 });

  const handleOnChange = (selectedOption: Measurement | string | null) => {
    if (selectedOption === null) {
      return handleClear();
    }
    if (typeof selectedOption !== 'string' && selectedOption.id) {
      return onChange(selectedOption);
    }
  };

  const searchInputMatchesOption = measurements.find(
    (measurement) => measurement.unit === searchValue,
  );

  return (
    <Autocomplete
      value={value}
      options={options}
      inputValue={searchValue}
      onChange={(_, val) => handleOnChange(val)}
      onOpen={() => setFetchEnabled(true)}
      onClose={() => setFetchEnabled(false)}
      freeSolo
      onInputChange={(e, value) => handleSearchChange(value === null ? '' : value)}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.unit || '')}
      clearOnEscape
      clearOnBlur={!searchInputMatchesOption}
      onBlur={searchInputMatchesOption ? () => handleOnChange(searchInputMatchesOption) : noop}
      blurOnSelect
      selectOnFocus
      handleHomeEndKeys
      loading={isFetching}
      clearIcon={<CloseIcon sx={{ width: 16, height: 16 }} />}
      componentsProps={{
        clearIndicator: {
          sx: {
            mr: 0.25,
            mb: 0.25,
            height: 20,
            width: 20,
            alignItems: 'center',
            display: value?.unit || debouncedSearchValue ? 'flex' : 'none',
          },
          onClick: handleClear,
        },
        paper: { sx: { width: 320 } },
        popper: { popperOptions: { placement: 'bottom-start' } },
      }}
      ListboxProps={{
        role: 'list-box',
        sx: { [`& .${buttonBaseClasses.root}`]: { pl: 2, pr: 1, py: 1 } },
      }}
      loadingText={<MeasurementItemSkeleton />}
      renderOption={(props, option, state) => {
        if (!!searchValue && (debouncedSearchValue !== searchValue || isFetching)) {
          return <MeasurementItemSkeleton />;
        }
        if (!measurements.length) {
          return <EmptyMeasurements searchValue={searchValue} />;
        }
        if (!searchValue || options.some((i) => i === option)) {
          return (
            <MeasurementItem
              {...props}
              key={option.unit}
              onGetNext={getNext}
              measurement={option}
              state={state}
              totalMeasurements={options.length}
              onChange={handleOnChange}
            />
          );
        }
      }}
      renderInput={({ inputProps, ...props }) => (
        <TextField
          inputProps={{
            maxLength: 255,
            'data-testid': testId,
            ...inputProps,
          }}
          data-testid={testId}
          variant="standard"
          sx={textFieldStyles}
          {...props}
          placeholder={intl.formatMessage({ id: 'recipes.ingredients.unit.label' })}
        />
      )}
    />
  );
};

export default MeasurementAutocomplete;

const textFieldStyles = {
  boxShadow: 'none',
  width: { xs: '50px', sm: '70px', md: '120px', lg: '160px' },
  [`& .${autocompleteClasses.input}`]: { width: '50px !important' },
  [`& .${autocompleteClasses.inputRoot}`]: { pr: '20px !important' },
  [`& .${outlinedInputClasses.root}`]: { p: 0 },
  [`& .${outlinedInputClasses.notchedOutline}`]: { border: 'none' },
  [`& .${outlinedInputClasses.notchedOutline} legend`]: { width: 0 },
  [`& .${buttonBaseClasses.root}`]: { p: 0.25 },
  [`& .${inputBaseClasses.root}`]: { bgcolor: 'bluegrey.50' },
  [`& .${inputClasses.root}`]: { px: 1, py: 0 },
};
