import { Chip, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { RecipeFormValues, RecipeRequirement } from 'types/recipes.types';

import { CountryFlag } from 'components/@common';

type Props = {
  recipe: RecipeFormValues;
  isReadOnly: boolean;
};

const RecipeCountry = ({ recipe, isReadOnly }: Props) => {
  if (!isReadOnly) return null;

  const isCore = recipe.requirementType === RecipeRequirement.Core;
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Typography variant="button">
        <FormattedMessage id="common.visible_for" />
      </Typography>
      {isCore && <Chip variant="labelDark" label={<FormattedMessage id="countries.ALL" />} />}
      {!isCore && <CountryFlag country={recipe.country} />}
    </Stack>
  );
};

export default RecipeCountry;
