import { Chat as ChatIcon, Edit as EditIcon, Print as PrintIcon } from '@mui/icons-material';
import { Badge, Box, Button, Collapse, IconButton, Stack } from '@mui/material';
import { uniqueId } from 'lodash';
import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useIsHQ } from 'hooks/useIsHQ';
import { useAddComment, useExportRecipe } from 'queries';
import { useCheckForDuplicateRecipeName } from 'queries/recipes/hooks';
import { useNotifications } from 'services/snackbar';
import { SaveOptions } from 'types/common.types';
import { RecipeTranslationStatus, Translation } from 'types/recipe-translations.types';
import { RecipeFormValues, RecipeStatus } from 'types/recipes.types';

import { ValidationDialog } from 'components/@form/ValidationDialog';
import {
  RecipeApprovalState,
  RecipeCommentDialog,
} from 'components/@recipe-form/RecipeForm/RecipeApprovalState';
import { SaveButton } from 'components/@recipe-form/RecipeForm/RecipeFormMainInfo/RecipeFormMainInfo.style';

import { useRecipeValidation } from './hooks';
import RecipeActionsMenu from './RecipeActionsMenu';

interface Props {
  isReadOnly: boolean;
  isSaving: boolean;
  onEditRecipe: () => void;
  onStopEditRecipe: () => void;
  saveRecipe: (options: SaveOptions) => void;
  calculateOnBrandness: (successCallback?: () => void) => void;
  recipeTranslations?: Translation[];
  onEditTranslations: () => void;
  isEditingTranslations: boolean;
}

// @TODO don't set buttons to loading when autosaving
const RecipeActions = ({
  isReadOnly,
  isSaving,
  onEditRecipe,
  onStopEditRecipe,
  saveRecipe,
  calculateOnBrandness,
  recipeTranslations = [],
  onEditTranslations,
  isEditingTranslations,
}: Props) => {
  const isHQ = useIsHQ();
  const notifications = useNotifications();
  const intl = useIntl();
  const { reset, getValues, setValue } = useFormContext<RecipeFormValues>();
  const status = useWatch<RecipeFormValues>({ name: 'status' }) as RecipeFormValues['status'];
  const comments = useWatch<RecipeFormValues>({ name: 'comments' }) as RecipeFormValues['comments'];
  const [isShowingComments, setIsShowingComments] = useState(false);
  const { isPending: isAddingComment, addComment } = useAddComment();
  const getValidationErrors = useRecipeValidation();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const checkError = useCheckForDuplicateRecipeName();

  const isDraft = [RecipeStatus.Draft, RecipeStatus.Rejected].includes(status);

  const verifiedTranslations = useMemo(
    () => recipeTranslations?.filter((t) => t.status === RecipeTranslationStatus.Verified) || [],
    [recipeTranslations],
  );

  const { exportRecipe, isPending: isExportingRecipe } = useExportRecipe();
  const handlePrintRecipe = () => {
    if (isExportingRecipe) return;
    exportRecipe(getValues('id'), { onSuccess: ({ url }) => window.open(url, '_blank') });
  };

  const handleAddComment = (message: string) => {
    addComment(
      { recipeId: getValues('id'), message },
      {
        onSuccess: () => {
          setIsShowingComments(false);
          setValue('comments', [
            { id: Number(uniqueId()), message, createdAt: new Date().toString() },
            ...comments,
          ]);
        },
      },
    );
  };

  const handleSave = () => {
    const validationErrors = getValidationErrors();
    if (!isDraft && validationErrors.length) {
      setValidationErrors(validationErrors);
      return;
    }
    saveRecipe({
      silent: false,
      onSuccess: () => {
        !isDraft && onStopEditRecipe();
        calculateOnBrandness();
      },
    });
  };

  const handleCancel = () => {
    reset();
    onStopEditRecipe();
  };

  const handleStatusChange = async ({
    successCallback,
    skipValidation,
  }: {
    successCallback?: () => void;
    skipValidation?: boolean;
  }) => {
    const validationErrors = getValidationErrors();
    if (!skipValidation && validationErrors.length) {
      return setValidationErrors(validationErrors);
    }

    saveRecipe({
      silent: true,
      onSuccess: () => calculateOnBrandness(() => successCallback?.()),
      onError: (error) => {
        const isDuplicate = checkError(error);
        if (!isDuplicate) {
          notifications.error({ message: intl.formatMessage({ id: 'error.general.text' }) });
        }
      },
    });
  };

  return (
    <Box display="flex" alignItems="center" className="no-print">
      <Collapse orientation="horizontal" in={!isEditingTranslations}>
        <Stack direction="row" gap={1} mr={2}>
          <RecipeActionsMenu />
          {isReadOnly &&
            isHQ &&
            [RecipeStatus.Submitted, RecipeStatus.Approved].includes(status) && (
              <IconButton onClick={onEditRecipe}>
                <EditIcon />
              </IconButton>
            )}
          {isReadOnly && !isDraft && (
            <IconButton onClick={handlePrintRecipe}>
              <PrintIcon />
            </IconButton>
          )}
          {!!comments.length && (
            <IconButton onClick={() => setIsShowingComments(true)}>
              <Badge badgeContent={comments.length} color="error">
                <ChatIcon />
              </Badge>
            </IconButton>
          )}
        </Stack>
      </Collapse>

      {!isDraft && ![RecipeStatus.Submitted].includes(status) && (
        <VerifyTranslationsButton
          onClick={onEditTranslations}
          hasTranslations={!!verifiedTranslations.length}
        />
      )}

      {!isReadOnly && (
        <>
          {!isDraft && (
            <Button
              variant="text"
              sx={(theme) => ({ color: theme.palette.black[80], px: 1 })}
              onClick={handleCancel}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
          )}

          <SaveButton loading={isSaving} onClick={handleSave} data-testid="save-recipe-button">
            <FormattedMessage id="general.actions.save" />
          </SaveButton>
        </>
      )}

      <Collapse in={!isEditingTranslations} orientation="horizontal" unmountOnExit>
        <Box ml={2}>
          <RecipeApprovalState
            verifiedTranslations={verifiedTranslations}
            isSavingRecipe={isSaving}
            onStopEditRecipe={onStopEditRecipe}
            onEditRecipe={onEditRecipe}
            handleStatusChange={handleStatusChange}
          />
        </Box>
      </Collapse>

      <RecipeCommentDialog
        title="recipes.creator.reject.dialog.read.title"
        isLoading={isAddingComment}
        addComment={handleAddComment}
        open={isShowingComments}
        onClose={() => setIsShowingComments(false)}
      />

      <ValidationDialog
        title="recipes.creator.validation_dialog.title"
        open={!!validationErrors.length}
        validationErrors={validationErrors}
        onClose={() => setValidationErrors([])}
      />
    </Box>
  );
};

export default RecipeActions;

const VerifyTranslationsButton = ({
  hasTranslations,
  onClick,
}: {
  hasTranslations: boolean;
  onClick: () => void;
}) => {
  if (hasTranslations) {
    return (
      <Button
        variant="contained"
        color="inherit"
        sx={{ px: 3, bgcolor: 'white !important' }}
        onClick={onClick}
      >
        <FormattedMessage id="recipes.translation.update" />
      </Button>
    );
  }
  return (
    <Button variant="contained" color="info" sx={{ px: 3 }} onClick={onClick}>
      <FormattedMessage id="recipes.translation.verify" />
    </Button>
  );
};
