import {
  Search as SearchIcon,
  ViewHeadline as ViewHeadlineIcon,
  ViewModule as ViewModuleIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { RESULTS_PER_PAGE } from 'constants/common.constants';
import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { EMPTY_RECIPE_FILTERS } from 'constants/recipes.constants';
import { Routes } from 'constants/routes.constants';
import { useMedia, useSearchState, useTableSort } from 'hooks';
import { useRecipes, useUser } from 'queries';
import { useSearchAndSortStore, useTranslationLocale } from 'store';
import { ActiveFilters, Season } from 'types/common.types';
import {
  BaseRecipeFilters,
  OptionalRecipeFilters,
  RecipeCategory,
  RecipeFilters,
  RecipeRequirement,
  RecipeStatus,
  RecipeStatusFilterOptions,
  RecipeTableColumns,
  RecipeTableHeader,
  RecipeType,
  RecipeView,
} from 'types/recipes.types';

import { BackButton } from 'components/@common';
import { RecipeAttentionBanner } from 'components/@recipe-overview/RecipeAttentionBanner';

import { RecipeFiltersList } from '../RecipeFiltersList';
import { RecipeGrid } from '../RecipeGrid';
import { RecipeTable } from '../RecipeTable';

type Props = {
  name: string;
  headers: RecipeTableHeader[];
  titleComponent: ReactNode;
  searchLabel?: string;
  statusFilter: RecipeStatusFilterOptions;
  enableCountryFilter?: boolean;
  enableTranslations?: boolean;
};

const RecipeViewer = ({
  name,
  headers,
  titleComponent,
  searchLabel,
  statusFilter,
  enableCountryFilter,
  enableTranslations,
}: Props) => {
  const { user, isLoading: isLoadingUser } = useUser();
  const { sm } = useMedia();
  const { translationLocale } = useTranslationLocale();
  const [selectedView, setSelectedView] = useState<RecipeView>(
    sm ? RecipeView.Grid : RecipeView.List,
  );

  useEffect(() => {
    setSelectedView(sm ? RecipeView.Grid : RecipeView.List);
  }, [sm]);
  const [filters, setFilters] = useState<ActiveFilters<RecipeFilters>>(EMPTY_RECIPE_FILTERS);

  const {
    sortBy: storedSortBy,
    setSortBy,
    sortDirection: storedSortDirection,
    setSortDirection,
    search: storedSearch,
    setSearch,
  } = useSearchAndSortStore({ name })();

  const { searchValue, debouncedSearchValue, handleSearchChange } = useSearchState({
    initialValue: storedSearch,
  });

  const { sort, sortBy, sortDirection, handleTableSort, resetTableSort } = useTableSort({
    initialSortBy: storedSortBy || RecipeTableColumns.UpdatedAt,
    initialSortDirection: storedSortDirection || 'desc',
  });

  useEffect(() => {
    setSearch(debouncedSearchValue);
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  }, [setSortBy, setSortDirection, setSearch, sortBy, sortDirection, debouncedSearchValue]);

  const onSwitchView = (newView: RecipeView) => {
    if (newView !== null) {
      setSelectedView(newView);
      resetTableSort();
    }
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleSearchChange(e.currentTarget.value);
  };

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    data: recipes,
    total,
    isLoading,
  } = useRecipes(
    {
      search: debouncedSearchValue,
      page_size: RESULTS_PER_PAGE,
      category: filters[BaseRecipeFilters.category] as RecipeCategory[],
      requirement_type: filters[BaseRecipeFilters.requirement] as RecipeRequirement[],
      type: filters[BaseRecipeFilters.type] as RecipeType[],
      language: translationLocale,
      season: filters[OptionalRecipeFilters.season] as Season[],
      country: filters[OptionalRecipeFilters.country] as string[],
      status:
        statusFilter === RecipeStatusFilterOptions.Published
          ? []
          : statusFilter === RecipeStatusFilterOptions.AllButPublished
            ? (filters[OptionalRecipeFilters.status].length > 0
                ? (filters[OptionalRecipeFilters.status] as RecipeStatus[])
                : // hide archived by default
                  Object.values(RecipeStatus).filter((status) => status !== RecipeStatus.Archived)
              ).filter((status) => status !== RecipeStatus.Published)
            : (filters[OptionalRecipeFilters.status] as RecipeStatus[]),
      sort,
    },
    { enabled: !!user, refetchOnMount: 'always' },
  );

  const getNext = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching, isFetchingNextPage]);

  const showResetFilter = useMemo(() => !isEqual(filters, EMPTY_RECIPE_FILTERS), [filters]);

  return (
    <>
      <Box display="flex" flexDirection="column" gap={3} width="100%">
        <BackButton to={Routes.Root} />

        {titleComponent}

        <TextField
          variant="standard"
          placeholder={searchLabel}
          value={searchValue}
          onChange={onSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ width: 24 }} />
              </InputAdornment>
            ),
          }}
        />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>
            <FormattedMessage
              id="recipes.amount"
              values={{
                amount: isLoading ? (
                  <CircularProgress size={16} color="inherit" sx={{ ml: 0.5 }} />
                ) : (
                  total
                ),
              }}
            />
          </Typography>
          {!sm && (
            <ToggleButtonGroup
              value={selectedView}
              exclusive
              onChange={(e, value) => onSwitchView(value)}
              aria-label="text alignment"
              size="small"
            >
              <ToggleButton value={RecipeView.Grid} aria-label="grid">
                <ViewModuleIcon />
              </ToggleButton>
              <ToggleButton value={RecipeView.List} aria-label="list">
                <ViewHeadlineIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        </Box>

        {statusFilter !== RecipeStatusFilterOptions.Published && (
          <RecipeAttentionBanner
            onClick={() =>
              setFilters((filters) => ({
                ...filters,
                [OptionalRecipeFilters.status]: [RecipeStatus.Submitted],
              }))
            }
          />
        )}

        <Divider sx={{ height: 2 }} />

        <Stack
          direction={sm ? 'column-reverse' : 'row'}
          gap={1}
          justifyContent={sm ? 'flex-start' : 'space-between'}
          alignItems={sm ? 'flex-start' : 'center'}
        >
          <RecipeFiltersList
            name={name}
            filters={filters}
            setFilters={setFilters}
            statusFilter={statusFilter}
            enableCountryFilter={enableCountryFilter}
          />
          {showResetFilter && (
            <Button color="inherit" onClick={() => setFilters(EMPTY_RECIPE_FILTERS)}>
              <FormattedMessage id="common.filters.reset" />
            </Button>
          )}
        </Stack>
      </Box>
      {selectedView === RecipeView.List ? (
        <RecipeTable
          recipes={recipes}
          recipeTotal={total}
          isLoading={isLoading || isLoadingUser}
          isFetchingNextPage={isFetchingNextPage}
          getNext={getNext}
          sortDirection={sortDirection}
          sortBy={sortBy as RecipeTableColumns}
          onSort={handleTableSort}
          tableHeaders={headers}
          isShowingTranslations={
            enableTranslations && translationLocale !== DEFAULT_TRANSLATION_LOCALE
          }
        />
      ) : (
        <RecipeGrid
          recipes={recipes}
          isLoading={isLoading || isLoadingUser}
          isFetchingNextPage={isFetchingNextPage}
          getNext={getNext}
          isShowingTranslations={
            enableTranslations && translationLocale !== DEFAULT_TRANSLATION_LOCALE
          }
        />
      )}
    </>
  );
};

export default RecipeViewer;
