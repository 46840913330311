import { Tooltip as MuiTooltip, TooltipProps, Zoom } from '@mui/material';
import { CSSProperties, memo, ReactElement, ReactNode } from 'react';

type Props = {
  title: ReactNode;
  children: ReactElement;
  placement?: TooltipProps['placement'];
  maxWidth?: CSSProperties['maxWidth'];
  offset?: [number, number];
  zIndex?: number;
  disabled?: boolean;
};

/**
 * Custom tooltip component to universalize the tooltip styles and behavior across the app.
 */
const Tooltip = ({
  title,
  children,
  placement = 'bottom',
  maxWidth,
  offset,
  zIndex = 10,
  disabled,
}: Props) => {
  return (
    <MuiTooltip
      TransitionComponent={Zoom}
      title={title}
      arrow
      placement={placement}
      slotProps={{
        popper: {
          sx: { maxWidth: maxWidth, zIndex, lineHeight: '20px' },
          modifiers: [{ name: 'offset', options: { offset } }],
        },
        tooltip: {
          sx: {
            color: 'text.secondary',
            px: 1.25,
            py: 0.75,
            textAlign: 'start',
            display: disabled ? 'none' : 'flex',
          },
        },
      }}
    >
      {/* div is needed for the tooltip to work in child's disabled state */}
      <div>{children}</div>
    </MuiTooltip>
  );
};

export default memo(Tooltip);
