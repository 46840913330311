import { Close as CloseIcon } from '@mui/icons-material';
import { MenuItem, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type Props = {
  searchValue: string;
};

const EmptyMeasurements = ({ searchValue }: Props) => {
  return (
    <MenuItem
      disabled
      key={searchValue}
      sx={{ px: 2, py: 1.5, gap: 2, display: 'flex', alignItems: 'center' }}
    >
      <CloseIcon sx={{ color: 'black.60', width: 24 }} />
      <Typography variant="body1">
        <FormattedMessage id="recipes.ingredients.unit.none" />
      </Typography>
    </MenuItem>
  );
};

export default EmptyMeasurements;
