import { WarningRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { uniq } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useExportMenu, useUser } from 'queries';
import { Menu, MenuItemStatus } from 'types/menus.types';

import { Tooltip } from 'components/@common/Tooltip';

import MenuExportEmails from './MenuExportEmails';
import MenuExportLanguages from './MenuExportLanguages';

interface Props {
  menu: Menu;
  isDisabled: boolean;
}

const MenuExporter = ({ menu, isDisabled }: Props) => {
  const posthog = usePostHog();
  const { user } = useUser();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { exportMenu, isPending: isExportingMenu } = useExportMenu();

  const [emails, setEmails] = useState<string[]>([]);
  const [languages, setLanguages] = useState<string[]>([]);

  useEffect(() => {
    if (!user?.email) return;
    setEmails((emails) => uniq([...emails, user.email]));
  }, [user?.email]);

  const handleExport = () => {
    exportMenu(
      { menuId: menu.id, emails, languages: languages.map((language) => ({ language })) },
      {
        onSuccess: () => {
          posthog.capture('exported_menu', { menu_id: menu.id });
          setIsDialogOpen(false);
        },
        onSettled: () => {
          handleClose();
        },
      },
    );
  };

  const handleClose = () => {
    setEmails([]);
    setLanguages([]);
    setIsDialogOpen(false);
  };

  const amountOfUnmatched = useMemo(
    () => menu.menuItems.filter((menuItem) => menuItem.status === MenuItemStatus.Unmatched).length,
    [menu.menuItems],
  );

  return (
    <>
      <Tooltip title={<FormattedMessage id="menus.export.tooltip" />}>
        <LoadingButton
          variant={'contained'}
          loading={isExportingMenu}
          color="info"
          disabled={isDisabled || !menu.menuItems.length}
          sx={{
            backgroundColor: 'white.100',
            color: 'info.main',
            '&:hover': {
              backgroundColor: 'grey.100',
            },
          }}
          onClick={() => setIsDialogOpen(true)}
        >
          <FormattedMessage id="menus.export" />
        </LoadingButton>
      </Tooltip>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <FormattedMessage id="menus.export.dialog.title" />
            <Button variant="text" sx={{ color: 'global01.main' }} onClick={handleClose}>
              <FormattedMessage id="common.close" />
            </Button>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Stack gap={4}>
            <MenuExportEmails emails={emails} onChange={setEmails} />
            <MenuExportLanguages menu={menu} languages={languages} onChange={setLanguages} />
          </Stack>
          <Collapse in={!!amountOfUnmatched && !!languages.length}>
            <Alert color="error" icon={<WarningRounded sx={{ width: 24 }} />} sx={{ mt: 3 }}>
              <FormattedMessage
                id="menus.export.dialog.unmatched_warning"
                values={{ amount: amountOfUnmatched }}
              />
            </Alert>
          </Collapse>
        </DialogContent>

        <DialogActions>
          <Button variant="text" sx={{ color: 'global01.main' }} onClick={handleClose}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <LoadingButton
            disabled={!languages.length || !emails.length}
            variant="contained"
            loading={isExportingMenu}
            onClick={handleExport}
          >
            <FormattedMessage
              id="menus.export.dialog.generate"
              values={{ amount: languages.length }}
            />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MenuExporter;
