import { Checkbox, List, MenuItem, Stack, Typography } from '@mui/material';
import { uniq } from 'lodash';
import { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getLanguageKey, getVerifiedState } from 'utils/recipe-translations.utils';

import { useLanguages } from 'queries';
import { Menu } from 'types/menus.types';
import { RecipePreview } from 'types/recipes.types';

import { InfoAlert } from 'components/@common';
import { TranslationStatus } from 'components/@recipe-translations/TranslationStatus';

import MenuExportSection from './MenuExportSection';

type Props = {
  menu: Menu;
  languages: string[];
  onChange: (languages: string[]) => void;
};

const MenuExportLanguages = ({ menu, languages, onChange }: Props) => {
  const { languages: enabledLanguages } = useLanguages();
  const intl = useIntl();

  const menuRecipes = menu.menuItems
    .map((menuItem) => menuItem.recipe)
    .filter((recipe): recipe is RecipePreview => recipe !== null);

  const allLanguages = useMemo(
    () =>
      enabledLanguages?.map((lang) => ({
        code: lang,
        label: intl.formatMessage({ id: getLanguageKey(lang) }),
        // ...getVerifiedState(menuRecipes, menu.menuItems.length, lang),
        ...getVerifiedState(),
      })) || [],
    //@TODO: uncomment when verified state is implemented
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enabledLanguages, intl, menu.menuItems.length, menuRecipes],
  );

  const totalRecipesToTranslate = useMemo(() => {
    const recipesToTranslate = allLanguages
      .flatMap((language) => (languages.includes(language.code) ? language.recipesToTranslate : []))
      .filter(Boolean);

    return uniq(recipesToTranslate).length;
  }, [allLanguages, languages]);

  const handleLanguageClick = (language: string) => {
    onChange(
      languages.includes(language)
        ? languages.filter((l) => l !== language)
        : [...languages, language],
    );
  };

  return (
    <MenuExportSection
      title="menus.export.dialog.languages.title"
      description="menus.export.dialog.languages.description"
      index={2}
    >
      <InfoAlert isOpen={totalRecipesToTranslate > 0} sx={{ mb: 2 }}>
        <Typography variant="body2">
          <FormattedMessage
            id="menus.export.dialog.translation_info"
            values={{ amount: totalRecipesToTranslate }}
          />
        </Typography>
      </InfoAlert>

      <List
        sx={(theme) => ({
          bgcolor: 'grey.50',
          borderRadius: theme.mixins.borderRadius.sm,
          maxHeight: theme.spacing(40),
          overflow: 'auto',
        })}
      >
        {allLanguages.map(({ label, code, verifiedPercentage, verifiedStatus }) => (
          <MenuItem
            key={code}
            onClick={() => handleLanguageClick(code)}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Stack direction="row" alignItems="center">
              <Checkbox
                color="default"
                disableRipple
                size="small"
                checked={languages.includes(code)}
                sx={(theme) => ({ color: `${theme.palette.black[60]} !important` })}
              />
              <Typography>{label}</Typography>
            </Stack>

            <TranslationStatus
              verifiedPercentage={verifiedPercentage}
              verifiedStatus={verifiedStatus}
            />
          </MenuItem>
        ))}
      </List>
    </MenuExportSection>
  );
};

export default memo(MenuExportLanguages);
