import { Stack, styled, TextareaAutosize, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { RecipeTranslationMethod } from 'types/recipe-translations.types';

const translationStatusMap: { [key: string]: I18nKey } = {
  [RecipeTranslationMethod.AI]: 'recipes.translation.status.ai',
  [RecipeTranslationMethod.Human]: 'recipes.translation.status.manual',
  [RecipeTranslationMethod.MEP]: 'recipes.translation.status.mep',
};

type Props = {
  initialValue: string;
  method: RecipeTranslationMethod;
  fieldName: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  isTitle?: boolean;
};

const TranslationField = ({
  initialValue,
  method,
  fieldName,
  disabled,
  onChange,
  isTitle,
}: Props) => {
  const { control } = useFormContext();
  const isHuman = method === RecipeTranslationMethod.Human;

  return (
    <Stack width="100%">
      <Controller
        control={control}
        name={fieldName}
        defaultValue={initialValue}
        disabled={disabled}
        render={({ field: { onChange: handleChange, ...field } }) => (
          <TextArea
            {...field}
            isTitle={isTitle}
            isHuman={isHuman}
            onChange={(e) => {
              onChange?.(e.target.value);
              handleChange(e);
            }}
            onFocus={(e) => e.target.select()}
          />
        )}
      />
      <Typography px={1.5} variant="caption" color="black.60">
        <FormattedMessage id={translationStatusMap[method || RecipeTranslationMethod.AI]} />
      </Typography>
    </Stack>
  );
};

export default TranslationField;

const TextArea = styled(TextareaAutosize, {
  shouldForwardProp: (prop) => prop !== 'isHuman' && prop !== 'isTitle',
})<{ isHuman?: boolean; isTitle?: boolean }>(({ isHuman, isTitle, theme, disabled }) => ({
  ...(isTitle ? theme.typography.h1 : theme.typography.body1),
  backgroundColor: 'transparent',
  paddingInline: theme.spacing(1.5),
  paddingBlock: theme.spacing(1),
  borderTop: 'none',
  borderInline: 'none',
  resize: 'none',
  borderColor: theme.palette.bluegrey[100],
  opacity: disabled ? 0.6 : 1,
  borderBottomStyle: disabled ? 'dotted' : 'solid',
  transition: 'border-color 0.2s ease-in',
  color: isHuman ? theme.palette.info.main : theme.palette.black[100],
  [`&:focus`]: {
    outline: 'none',
    color: isHuman ? theme.palette.info.main : theme.palette.black[100],
    borderColor: isHuman ? theme.palette.info.main : theme.palette.black[60],
  },
  [`&::selection`]: {
    color: isHuman ? theme.palette.info.main : theme.palette.black[100],
    backgroundColor: isHuman ? theme.palette.info.light : theme.palette.black[10],
  },
}));
