import { RotateLeftRounded as RotateLeftRoundedIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CircularProgress, Collapse, Stack, Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { useGetOnBrandnessColor } from 'utils/recipe.utils';

import { RecipeFormValues, RecipeStatus } from 'types/recipes.types';

type Props = {
  isCalculating: boolean;
  disableActions: boolean | undefined;
  isReadOnly: boolean;
  onToggleExplanation: () => void;
  onCalculate: () => void;
};

const RecipeOnBrandness = ({
  isCalculating,
  disableActions,
  isReadOnly,
  onToggleExplanation,
  onCalculate,
}: Props) => {
  const onBrandnessScore = useWatch<RecipeFormValues>({
    name: 'onBrandScore',
  }) as number | null;
  const ingredients = useWatch<RecipeFormValues>({
    name: 'ingredients',
  }) as RecipeFormValues['ingredients'];
  const status = useWatch<RecipeFormValues>({
    name: 'status',
  }) as RecipeStatus;
  const isPublished = status === RecipeStatus.Published;

  // If no ingredients have been added yet, we won't display an onbrandness score
  const hasIngredients =
    ingredients.length > 0 && ingredients.some((ingredient) => ingredient.name);
  const onBrandness = hasIngredients ? onBrandnessScore : null;

  // If the recipe is published, the onBrandness score is 100
  const onBrandnesScore = isPublished ? 100 : onBrandness;

  return (
    <Card
      elevation={0}
      variant={onBrandness !== null ? 'elevation' : 'outlined'}
      sx={{
        p: 2,
        pb: 1,
        gap: 1,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'visible',
        bgcolor: onBrandness !== null ? 'bg.paper' : 'transparent',
      }}
    >
      <Typography sx={{ color: (theme) => theme.palette.global01.main }}>
        <FormattedMessage id="recipes.props.onbrandness.label" />
      </Typography>

      <OnBrandnessBar onBrandness={onBrandnesScore} />

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="no-print"
      >
        <Stack direction="row" alignItems="center">
          {!isPublished && (
            <Collapse
              in={onBrandness !== null && !disableActions}
              orientation="horizontal"
              unmountOnExit
              mountOnEnter
            >
              <Button
                variant="link"
                onClick={onToggleExplanation}
                sx={{
                  fontSize: '0.8125rem',
                  fontWeight: 500,
                  color: 'global01.main',
                  whiteSpace: 'nowrap',
                }}
              >
                <FormattedMessage id="general.calculation.how.label" />
              </Button>
            </Collapse>
          )}
        </Stack>
        {!disableActions && !isReadOnly && (
          <LoadingButton
            loading={isCalculating}
            loadingIndicator={
              <Stack direction="row" gap={1} alignItems="center">
                <CircularProgress size={16} color="primary" />
                <Typography variant="buttonSmall" textTransform="none">
                  <FormattedMessage id="recipes.props.onbrandness.calculating" />
                </Typography>
              </Stack>
            }
            variant="text"
            size="small"
            color="inherit"
            onClick={onCalculate}
            sx={(theme) => ({
              color: 'grey.700',
              display: 'flex',
              alignItems: 'center',
              ...theme.typography.buttonSmall,
              textTransform: 'none',
            })}
          >
            <RotateLeftRoundedIcon sx={{ width: 16 }} />
            <FormattedMessage id="recipes.props.onbrandness.recalculate" />
          </LoadingButton>
        )}
      </Stack>
    </Card>
  );
};

export default RecipeOnBrandness;

type OnBrandnessBarProps = {
  onBrandness: number | null;
};

const OnBrandnessBar = ({ onBrandness }: OnBrandnessBarProps) => {
  const getOnBrandnessColor = useGetOnBrandnessColor();

  return (
    <Box
      my="auto"
      height={32}
      borderRadius={(theme) => theme.mixins.borderRadius.full}
      width="100%"
      overflow="hidden"
      bgcolor="grey.50"
      border={onBrandness !== null ? 'none' : '1px solid'}
      borderColor="black.60"
      sx={{
        opacity: onBrandness !== null ? 1 : 0.5,
        transition: 'all 0.3s ease-out',
        '@media print': {
          border: 'none',
        },
      }}
    >
      <Stack
        width={onBrandness !== null ? `${onBrandness}%` : '33%'}
        height="100%"
        direction="row"
        gap={1}
        alignItems="center"
        p={1.5}
        bgcolor={onBrandness !== null ? getOnBrandnessColor(onBrandness) : 'black.40'}
        sx={{ transition: 'width 0.3s ease-out' }}
      >
        {onBrandness !== null ? (
          <Typography
            variant="chip"
            sx={{
              color: (theme) => theme.palette.black[90],
              '@media print': { color: (theme) => theme.palette.black[100] },
            }}
          >
            {`${onBrandness || 0}%`}
          </Typography>
        ) : (
          <>
            <Box
              flex={1}
              height={8}
              bgcolor="primary.light"
              borderRadius={(theme) => theme.mixins.borderRadius.full}
              sx={{ opacity: 0.5 }}
            />
            <Typography
              variant="subtitle1"
              fontWeight={600}
              sx={{ color: (theme) => theme.palette.primary.light, opacity: 0.5 }}
            >
              %
            </Typography>
          </>
        )}
      </Stack>
    </Box>
  );
};
