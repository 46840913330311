import { Box, Divider, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { RecipeFormIngredient, RecipeFormValues } from 'types/recipes.types';

import ErrorBoundary from 'components/@boundaries/ErrorBoundary';
import { SortableList } from 'components/@common';
import { useIsInMenuItemDrawer } from 'components/@menu-form';
import { IngredientsTranslationsTable } from 'components/@recipe-translations';

import { IngredientItem } from './IngredientItem';

type Props = {
  isReadOnly: boolean;
  isEditingTranslations: boolean;
  targetTranslationLocale: string;
  isLoadingTranslations: boolean;
  isShowingTranslation: boolean;
};

const RecipeFormIngredients = ({
  isReadOnly,
  isEditingTranslations,
  targetTranslationLocale,
  isLoadingTranslations,
  isShowingTranslation,
}: Props) => {
  const isInMenuItemDrawer = useIsInMenuItemDrawer();

  const { fields, append, remove, move } = useFieldArray<RecipeFormValues>({
    name: 'ingredients',
  });

  return (
    <ErrorBoundary boundary="recipe-ingredients">
      <Stack gap={1} sx={{ '@media print': { pageBreakInside: 'avoid' } }}>
        <Typography textTransform="capitalize">
          <FormattedMessage id="recipes.ingredients.label" />
        </Typography>

        {isEditingTranslations && (
          <IngredientsTranslationsTable
            targetLocale={targetTranslationLocale}
            isLoading={isLoadingTranslations}
          />
        )}

        {!isEditingTranslations && (
          <Box
            width="full"
            bgcolor="bg.paper"
            overflow="hidden"
            borderRadius={(theme) => theme.mixins.borderRadius.base}
          >
            <Stack direction="row" justifyItems="center" py={0.75} gap={2} px={2}>
              <Typography flex={1} variant="body2" fontWeight={500} pl={!isReadOnly ? 7 : 0}>
                <FormattedMessage id="general.label.name" />
              </Typography>

              <Typography
                flex={2}
                sx={(theme) => ({ [theme.breakpoints.down('lg')]: { flex: 2 } })}
                variant="body2"
                fontWeight={500}
              >
                <FormattedMessage id="recipes.ingredients.quantity.label" />
                {' / '}
                <FormattedMessage id="recipes.ingredients.unit.label" />
              </Typography>

              <Typography flex={1} variant="body2" fontWeight={500} pr={!isReadOnly ? 7 : 0}>
                <FormattedMessage id="recipes.ingredients.add.remarks" />
              </Typography>
            </Stack>

            <Divider />

            <SortableList
              items={fields}
              placeholder="recipes.ingredients.empty"
              addButtonTestId="add-ingredient-button"
              isReadOnly={isReadOnly}
              renderItem={(index) => (
                <IngredientItem
                  key={index}
                  index={index}
                  ingredient={fields[index] as RecipeFormIngredient}
                  isReadOnly={isReadOnly}
                  isShowingTranslation={isShowingTranslation}
                  isLoadingTranslations={isLoadingTranslations}
                />
              )}
              buttonText={<FormattedMessage id="recipes.ingredients.add" />}
              withBottomBorder={isInMenuItemDrawer}
              onAddItem={() =>
                append({
                  name: '',
                  refId: null,
                  isIngredientRecipe: false,
                  measurements: [
                    { quantity: '', unit: '', id: null, recipeIngredientId: null },
                    { quantity: '', unit: '', id: null, recipeIngredientId: null },
                  ],
                  notes: '',
                } as RecipeFormIngredient)
              }
              onRemoveItem={remove}
              onMoveItem={move}
            />
          </Box>
        )}
      </Stack>
    </ErrorBoundary>
  );
};

export default memo(RecipeFormIngredients);
