import {
  GetRecipeTranslationsParams,
  RecipeTranslationsResponse,
  UpdateRecipeTranslationsParams,
} from 'types/recipe-translations.types';
import {
  ExportRecipeResponse,
  GetIngredientOptions,
  GetMeasurementOptions,
  GetRecipeOptions,
  Ingredient,
  Measurement,
  PostIngredientOptions,
  PostMeasurementParams,
  PostRecipeParams,
  RecipeCommentParams,
  RecipeComplianceRuleSet,
  RecipeDetailed,
  RecipePreview,
  RecipeStatus,
  UpdateRecipeParams,
  UpdateRecipePerformanceDataParams,
} from 'types/recipes.types';

import RestApiService from '.';
import { ApiResponse } from './api.types';

export default {
  getRecipes(this: RestApiService, options: GetRecipeOptions) {
    return this.api.get<ApiResponse<RecipePreview[]>>('/recipes', { params: options });
  },
  getRecipe(this: RestApiService, recipeId: number) {
    return this.api.get<ApiResponse<RecipeDetailed>>(`/recipes/${recipeId}`);
  },
  exportRecipe(this: RestApiService, recipeId: number) {
    return this.api.post<ApiResponse<ExportRecipeResponse>>(`/recipes/${recipeId}/export`);
  },
  getRecipeComplianceRules(this: RestApiService) {
    return this.api.get<ApiResponse<RecipeComplianceRuleSet>>(`/recipes/compliance-rules`);
  },
  postRecipe(this: RestApiService, data: PostRecipeParams) {
    return this.api.post<ApiResponse<RecipeDetailed>>(`/recipes`, {
      data: {
        name: data.name || null,
        seasons: data.seasons || [],
        notes: data.notes || [],
        ingredients: data.ingredients || [],
        preparationSteps: data.preparationSteps || [
          { description: '', order: 0, image: '' },
          { description: '', order: 1, image: '' },
          { description: '', order: 2, image: '' },
          { description: '', order: 3, image: '' },
        ],
        type: data.type || null,
        batchImage: data.batchImage || null,
        image: data.image || null,
        category: data.category || null,
        country: data.country || null,
        requirementType: data.requirementType || null,
        status: RecipeStatus.Draft,
      },
    });
  },
  putRecipe(this: RestApiService, data: UpdateRecipeParams) {
    return this.api.put<ApiResponse<RecipeDetailed>>(`/recipes/${data.id}`, {
      data: { ...data },
    });
  },
  archiveRecipe(this: RestApiService, recipeId: number) {
    return this.api.delete<void>(`/recipes/${recipeId}`);
  },
  getMeasurements(this: RestApiService, options: GetMeasurementOptions) {
    return this.api.get<ApiResponse<Measurement[]>>('/measurements', {
      params: options,
    });
  },
  postMeasurement(this: RestApiService, data: PostMeasurementParams) {
    return this.api.post<ApiResponse<Measurement[]>>('/measurements', { data });
  },
  getIngredients(this: RestApiService, options: GetIngredientOptions) {
    return this.api.get<ApiResponse<Ingredient[]>>('/ingredients', {
      params: options,
    });
  },
  postIngredient(this: RestApiService, data: PostIngredientOptions) {
    return this.api.post<ApiResponse<Ingredient>>('/ingredients', { data });
  },
  calculateOnBrandness(this: RestApiService, recipeId: number) {
    return this.api.patch<ApiResponse<Pick<RecipeDetailed, 'onBrand'>>>(
      `/recipes/${recipeId}/on-brand`,
    );
  },
  putPerformanceData(
    this: RestApiService,
    { recipeId, performanceData }: UpdateRecipePerformanceDataParams,
  ) {
    return this.api.put<ApiResponse<RecipeDetailed['performanceData']>>(
      `/recipes/${recipeId}/performance-data`,
      { data: performanceData },
    );
  },
  // APPROVAL
  submitRecipe(this: RestApiService, recipeId: number) {
    return this.api.patch<void>(`/recipes/${recipeId}/submit`);
  },
  approveRecipe(this: RestApiService, recipeId: number) {
    return this.api.patch<void>(`/recipes/${recipeId}/approve`);
  },
  rejectRecipe(this: RestApiService, { recipeId, message }: RecipeCommentParams) {
    return this.api.patch<void>(`/recipes/${recipeId}/reject`, {
      data: { message },
    });
  },
  publishRecipe(this: RestApiService, recipeId: number) {
    return this.api.patch<void>(`/recipes/${recipeId}/publish`);
  },
  addComment(this: RestApiService, { recipeId, message }: RecipeCommentParams) {
    return this.api.post<void>(`/recipes/${recipeId}/comment`, {
      data: { message },
    });
  },
  // TRANSLATIONS
  translateRecipe(this: RestApiService, recipeId: number) {
    return this.api.post<void>(`/recipes/${recipeId}/translate`);
  },
  getRecipeTranslations(this: RestApiService, { recipeId, language }: GetRecipeTranslationsParams) {
    return this.api.get<ApiResponse<RecipeTranslationsResponse>>(
      `/recipes/${recipeId}/translations/${language}`,
    );
  },
  putRecipeTranslations(
    this: RestApiService,
    { recipeId, language, translation }: UpdateRecipeTranslationsParams,
  ) {
    return this.api.put<ApiResponse<RecipeTranslationsResponse>>(
      `/recipes/${recipeId}/translations/${language}`,
      { data: translation },
    );
  },
};
