import { Check as CheckIcon } from '@mui/icons-material';
import { Box, Collapse, IconButton, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import React, { memo, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';

import { COUNTRIES_ALL, recipeRequirementsMap, recipeTypesMap } from 'constants/recipes.constants';
import { RecipePreview } from 'types/recipes.types';

import { CountryFlag, Image } from 'components/@common';
import PreviewRecipeDialog from 'components/@menu-form/MenuItemDrawer/MenuItemRecipeList/PreviewRecipeDialog';

import CreateVariantButton from './CreateVariantButton';

interface Props {
  recipes: RecipePreview[];
  title: I18nKey;
  isLoading: boolean;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  hasSearchValue?: boolean;
  isUpdatingMenuItem: boolean;
  loadingItems?: number;
  fetchNextPage?: () => void;
  onSelectRecipe: (recipe: RecipePreview, params?: { silent?: boolean }) => void;
}

const RecipeList = ({
  recipes,
  title,
  isLoading,
  hasNextPage,
  isFetchingNextPage,
  hasSearchValue = false,
  loadingItems = 3,
  isUpdatingMenuItem,
  fetchNextPage,
  onSelectRecipe,
}: Props) => {
  const [isPreviewRecipeDialogOpen, setIsPreviewRecipeDialogOpen] = useState(false);
  const [previewRecipe, setPreviewRecipe] = useState<RecipePreview | null>(null);

  const getNext = (inView: boolean) => {
    if (inView && hasNextPage && !isFetchingNextPage && !isLoading) {
      fetchNextPage?.();
    }
  };

  const isEmpty = !recipes?.length && !isLoading && !isFetchingNextPage;
  const show = !isEmpty || hasSearchValue;

  const handleRowClick = (recipe: RecipePreview) => {
    if (isUpdatingMenuItem) return;

    setPreviewRecipe(recipe);
    setIsPreviewRecipeDialogOpen(true);
  };

  return (
    <Collapse in={show}>
      <Stack>
        <Box px={3} py={1} bgcolor="bluegrey.50">
          <Typography variant="subtitle2">
            <FormattedMessage id={title} />
          </Typography>
        </Box>

        {isEmpty && show && (
          <Typography
            variant="body2"
            px={3}
            py={2}
            sx={{ color: (theme) => theme.palette.bluegrey[800] }}
          >
            <FormattedMessage id="recipes.empty" />
          </Typography>
        )}

        {recipes.map((recipe, index) => (
          <Stack
            gap={2}
            key={recipe.id}
            px={3}
            py={2}
            direction="row"
            alignItems="center"
            borderBottom="1px solid"
            sx={(theme) => ({
              borderColor: theme.palette.bluegrey[50],
              cursor: 'pointer',
              transition: 'background-color 0.2s',
              '&:hover': {
                backgroundColor: theme.palette.bluegrey[100],
              },
            })}
            onClick={() => handleRowClick(recipe)}
          >
            <Image
              src={recipe.batchImage}
              height={64}
              width={64}
              objectFit="cover"
              sx={{ flexShrink: 0 }}
            />
            <Stack gap={0.5}>
              <Stack direction="row" gap={1} alignItems="center">
                {recipe.country !== COUNTRIES_ALL && <CountryFlag country={recipe.country} />}
                <Typography variant="body1" sx={{ color: (theme) => theme.palette.black[90] }}>
                  {recipe.name || <FormattedMessage id="recipes.label.untitled" />}
                </Typography>
              </Stack>

              <Stack direction="row" gap={3}>
                {recipe.requirementType && (
                  <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[700] }}>
                    <FormattedMessage id={recipeRequirementsMap[recipe.requirementType]} />
                  </Typography>
                )}
                {recipe.type && (
                  <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[700] }}>
                    <FormattedMessage id={recipeTypesMap[recipe.type]} />
                  </Typography>
                )}
                <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[700] }}>
                  {recipe.seasons?.length === 4 ? (
                    <FormattedMessage id="general.seasons.all" />
                  ) : (
                    recipe.seasons?.map((season, index) => (
                      <React.Fragment key={season}>
                        <FormattedMessage
                          id={`general.seasons.${season.toLowerCase()}` as I18nKey}
                        />
                        {index !== recipe.seasons?.length - 1 && ', '}
                      </React.Fragment>
                    ))
                  )}
                </Typography>
              </Stack>
              {index === recipes?.length - 1 && <InView onChange={getNext} />}
            </Stack>

            <CreateVariantButton
              originalRecipe={recipe}
              isLoading={isUpdatingMenuItem}
              selectRecipe={onSelectRecipe}
            />

            <Tooltip title={<FormattedMessage id="menus.items.linked_recipe.link" />}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  !isUpdatingMenuItem && onSelectRecipe(recipe);
                }}
              >
                <CheckIcon sx={{ height: 24, width: 24, color: 'black.50' }} />
              </IconButton>
            </Tooltip>
          </Stack>
        ))}

        {(!show || isLoading || isFetchingNextPage) &&
          Array.from({ length: loadingItems }).map((_, index) => <SkeletonRow key={index} />)}
      </Stack>

      <PreviewRecipeDialog
        recipe={previewRecipe}
        isOpen={isPreviewRecipeDialogOpen}
        isLinking={isUpdatingMenuItem}
        onClose={() => setIsPreviewRecipeDialogOpen(false)}
        handleLinkRecipe={() => {
          if (!previewRecipe) return;
          setIsPreviewRecipeDialogOpen(false);
          onSelectRecipe(previewRecipe);
        }}
      />
    </Collapse>
  );
};

const SkeletonRow = () => {
  return (
    <Stack
      gap={2}
      px={3}
      py={2}
      direction="row"
      alignItems="center"
      borderBottom="1px solid"
      sx={(theme) => ({ borderColor: theme.palette.bluegrey[50] })}
    >
      <Skeleton height={64} width={64} variant="rounded" />
      <Stack gap={0.5}>
        <Skeleton variant="text" width={200} height={24} />
        <Stack direction="row" gap={3}>
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton key={index} variant="text" width={50} height={20} />
          ))}
        </Stack>
      </Stack>
      <Skeleton sx={{ ml: 'auto' }} width={24} height={24} />
      <Skeleton width={24} height={24} />
    </Stack>
  );
};

export default memo(RecipeList);
