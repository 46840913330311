import { LoadingButton } from '@mui/lab';
import { Box, Grid2 as Grid, Stack, TextField, Typography } from '@mui/material';
import { SignInOutput } from 'aws-amplify/auth';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { errorMessageFor } from 'utils/form.utils';

import { useCreateCompleteNewPassword } from 'queries';
import { useNotifications } from 'services/snackbar';
import {
  COGNITO_CHALLENGE_STATE_MAP,
  CompleteSignupFormValues,
  LoginState,
} from 'types/auth.types';

import { MIN_PASSWORD_CHARS } from 'components/@auth/PasswordField/constants';

import { PasswordField } from '../PasswordField';

interface Props {
  onLoginStateChange: (state: LoginState, output?: SignInOutput) => void;
}

export function CompleteSignupForm({ onLoginStateChange }: Props) {
  const notifications = useNotifications();
  const intl = useIntl();
  const [passwordApiError, setPasswordApiError] = useState<React.ReactNode>('');

  const { isPending, error, completeNewPassword } = useCreateCompleteNewPassword();

  const formMethods = useForm<CompleteSignupFormValues>({
    defaultValues: {
      newPassword: '',
      newPasswordRepeat: '',
      givenName: '',
      familyName: '',
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const completeProfile = (values: CompleteSignupFormValues) => {
    setPasswordApiError('');

    completeNewPassword(
      {
        newPassword: values.newPassword,
        attributes: {
          given_name: values.givenName,
          family_name: values.familyName,
        },
      },
      {
        onSuccess: (user) => {
          if (user.nextStep && COGNITO_CHALLENGE_STATE_MAP[user.nextStep.signInStep]) {
            const nextState = COGNITO_CHALLENGE_STATE_MAP[user.nextStep.signInStep];
            if (!nextState) return;
            onLoginStateChange(nextState, user);
            return;
          }
          onLoginStateChange(LoginState.Success, user);
        },
        onError: (error) => {
          notifications.error({
            message: intl.formatMessage({ id: 'common.error_messages.error' }),
          });

          switch (error.name) {
            case 'InvalidPasswordException':
              setPasswordApiError(
                <FormattedMessage
                  id="error.password.validation"
                  values={{ amount: MIN_PASSWORD_CHARS }}
                />,
              );
              break;
            case 'NotAuthorizedException':
              window.location.reload();
              break;
          }
        },
      },
    );
  };

  return (
    <>
      <Typography variant="h4">
        <FormattedMessage id="login.complete_signup.title" />
      </Typography>
      <Typography mt={2} mb={5} variant="body1">
        <FormattedMessage id="login.complete_signup.description" />
      </Typography>

      <form onSubmit={handleSubmit(completeProfile)}>
        <FormProvider {...formMethods}>
          <Stack gap={3}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  {...register('givenName', {
                    required: errorMessageFor(intl, {
                      type: 'required',
                      label: 'firstname',
                    }),
                  })}
                  label={<FormattedMessage id="form.label.firstname" />}
                  name="givenName"
                  type="text"
                  inputMode="text"
                  autoComplete="given-name"
                  error={!!errors.givenName}
                  helperText={errors?.givenName?.message}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  {...register('familyName', {
                    required: errorMessageFor(intl, {
                      type: 'required',
                      label: 'lastname',
                    }),
                  })}
                  label={<FormattedMessage id="form.label.lastname" />}
                  name="familyName"
                  type="text"
                  inputMode="text"
                  autoComplete="family-name"
                  error={!!errors.familyName}
                  helperText={errors?.familyName?.message}
                />
              </Grid>
            </Grid>

            <PasswordField apiError={passwordApiError} />
          </Stack>

          <Box display="flex" justifyContent="flex-end">
            <LoadingButton variant="contained" sx={{ mt: 5 }} loading={isPending} type="submit">
              <FormattedMessage id="form.button.submit" />
            </LoadingButton>
          </Box>
          {error && (
            <Box mt={2} px={2} py={1} borderRadius={1} bgcolor="error.light">
              <Typography variant="body2">
                <FormattedMessage id="error.complete_signup" />
              </Typography>
            </Box>
          )}
        </FormProvider>
      </form>
    </>
  );
}
