import { Grid2 as Grid, List, ListItem } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMenuComplianceRules } from 'queries';
import { Menu, MenuComplianceRules } from 'types/menus.types';

import { InfoAlert } from 'components/@common';

type Props = {
  menu: Menu;
  isProcessing: boolean;
  setShowHowWeCalculateDialog: (show: boolean) => void;
};

const MenuComplianceAlerts = ({ menu, isProcessing, setShowHowWeCalculateDialog }: Props) => {
  const { data: complianceRules } = useMenuComplianceRules();

  const [showComplianceErrors, setShowComplianceErrors] = useState(
    menu.complianceScore !== 100 && !!menu.complianceErrors?.length,
  );

  useEffect(() => {
    setShowComplianceErrors(menu.complianceScore !== 100 && !!menu.complianceErrors?.length);
  }, [menu.complianceErrors?.length, menu.complianceScore]);

  return (
    <Grid
      size={{ xs: 12 }}
      sx={(theme) => ({ mb: 2, order: 3, [theme.breakpoints.down('md')]: { order: 2 } })}
    >
      <InfoAlert
        isOpen={showComplianceErrors && !isProcessing}
        onClose={() => setShowComplianceErrors(false)}
        onAction={() => setShowHowWeCalculateDialog(true)}
        actionLabel={<FormattedMessage id="general.calculation.how.label" />}
        arrow
      >
        <List dense sx={{ py: 0 }}>
          {!!menu.complianceErrors &&
            menu.complianceErrors.map(({ rule, properties }) => {
              switch (rule) {
                case MenuComplianceRules.GlobalDishRatio:
                  return (
                    <ListItem sx={{ display: 'list-item', p: 0 }} key={rule}>
                      <FormattedMessage
                        id="menus.compliance.errors.global_dish_ratio"
                        values={{
                          requiredAmount: complianceRules?.globalDishRules?.[0]?.rangeMin || '...',
                          currentAmount: properties?.globalPercentage,
                          strong: (message: ReactNode) => <strong>{message}</strong>,
                        }}
                      />
                    </ListItem>
                  );
                case MenuComplianceRules.MaxItemLimit:
                  return (
                    <ListItem sx={{ display: 'list-item', p: 0 }} key={rule}>
                      <FormattedMessage
                        id="menus.compliance.errors.too_many_items"
                        values={{
                          strong: (message: ReactNode) => <strong>{message}</strong>,
                        }}
                      />
                    </ListItem>
                  );
              }
            })}
        </List>
      </InfoAlert>
    </Grid>
  );
};

export default MenuComplianceAlerts;
