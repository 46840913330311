import { Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import {
  RecipeStatusFilterOptions,
  RecipeTableColumns,
  RecipeTableHeader,
} from 'types/recipes.types';

import RecipeViewer from 'components/@recipe-overview/RecipeViewer/RecipeViewer';
import { TranslationSelector, TranslationStatus } from 'components/@recipe-translations';

export const COOKBOOK_TABLE_HEADERS: RecipeTableHeader[] = [
  { id: RecipeTableColumns.Image, label: 'recipes.props.image', width: '10%' },
  {
    id: RecipeTableColumns.Name,
    label: 'general.title.label',
    width: '30%',
    sortable: true,
    initialSort: 'asc',
  },
  // { id: RecipeTableColumns.OnBrand, label: 'recipes.props.onbrand' },
  { id: RecipeTableColumns.Type, label: 'recipes.props.type', width: '15%' },
  { id: RecipeTableColumns.Season, label: 'general.seasons.label', width: '18%' },
  // { id: RecipeTableColumns.Performance, label: 'recipes.props.performance' },
  { id: RecipeTableColumns.Translation, label: 'recipes.props.translation', width: '13%' },
  { id: RecipeTableColumns.Countries, label: 'recipes.props.countries', width: '10%' },
  {
    id: RecipeTableColumns.UpdatedAt,
    label: 'common.updated_at',
    width: '15%',
    sortable: true,
    initialSort: 'desc',
  },
];

const Cookbook = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'alain_ai.label' })} -{' '}
          {intl.formatMessage({ id: 'cookbook.label' })}
        </title>
      </Helmet>

      <RecipeViewer
        name="cookbook"
        enableTranslations
        headers={COOKBOOK_TABLE_HEADERS}
        statusFilter={RecipeStatusFilterOptions.Published}
        enableCountryFilter
        titleComponent={
          <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
            <Typography variant="h4">
              <FormattedMessage id="cookbook.label" />
            </Typography>
            <TranslationSelector
              menuItemSuffix={(lang, status) => {
                if (lang === DEFAULT_TRANSLATION_LOCALE) {
                  return (
                    <TranslationStatus
                      verifiedStatus={status}
                      aiTooltip="recipes.translations.ai.tooltip"
                      language={lang}
                    />
                  );
                }
              }}
            />
          </Stack>
        }
        searchLabel={intl.formatMessage({ id: 'cookbook.search' })}
      />
    </>
  );
};

export default Cookbook;
