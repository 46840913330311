import {
  Close as CloseIcon,
  PlaylistAddRounded as PlaylistAddRoundedIcon,
  QueueRounded as QueueRoundedIcon,
} from '@mui/icons-material';
import {
  Autocomplete,
  autocompleteClasses,
  buttonBaseClasses,
  MenuItem,
  outlinedInputClasses,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { noop, uniq } from 'lodash';
import { memo, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { InView } from 'react-intersection-observer';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useSearchState } from 'hooks';
import { useIsHQ } from 'hooks/useIsHQ';
import { useCreateIngredient, useCreateRecipe, useIngredients, useRecipes, useUser } from 'queries';
import { useNotifications } from 'services/snackbar';
import {
  NewIngredientType,
  RecipeFormIngredient,
  RecipeFormValues,
  RecipeStatus,
  RecipeType,
} from 'types/recipes.types';

import AutocompleteAddItem from './AutocompleteAddItem';
import AutocompleteItem from './AutocompleteItem';

type Props = {
  value: RecipeFormIngredient | null;
  onChange: (newValue: RecipeFormIngredient | null) => void;
  isReadOnly?: boolean;
  index: number;
  isShowingTranslation?: boolean;
  translatedName?: string;
};

const IngredientAutocomplete = ({
  value,
  onChange,
  isReadOnly,
  index,
  isShowingTranslation,
  translatedName,
}: Props) => {
  const intl = useIntl();
  const notifications = useNotifications();
  const navigate = useNavigate();
  const { recipeId } = useParams();
  const { user } = useUser();
  const { createIngredient } = useCreateIngredient();
  const { createRecipe } = useCreateRecipe();
  const isHQ = useIsHQ();

  const selectedIngredients = useWatch<RecipeFormValues>({
    name: 'ingredients',
  }) as RecipeFormIngredient[];

  const { searchValue, debouncedSearchValue, handleSearchChange } = useSearchState();
  const [fetchEnabled, setFetchEnabled] = useState(false);

  const {
    fetchNextPage: fetchNextIngredientsPage,
    hasNextPage: hasNextIngredientsPage,
    isFetchingNextPage: isFetchingNextIngredientsPage,
    data: ingredients,
    isFetching: isFetchingIngredients,
  } = useIngredients(
    { page_size: 5, search: debouncedSearchValue, sort: 'title' },
    { enabled: fetchEnabled && !isReadOnly },
  );

  const {
    fetchNextPage: fetchNextRecipesPage,
    hasNextPage: hasNextRecipesPage,
    isFetchingNextPage: isFetchingNextRecipesPage,
    data: recipes,
    isFetching: isFetchingRecipes,
  } = useRecipes(
    {
      page_size: 5,
      search: debouncedSearchValue,
      status: [
        RecipeStatus.Draft,
        RecipeStatus.Rejected,
        RecipeStatus.Submitted,
        RecipeStatus.Approved,
        RecipeStatus.Published,
      ],
      type: [RecipeType.MiseEnPlace],
      sort: 'title',
    },
    { enabled: fetchEnabled && !isReadOnly },
  );

  const areResultsUpToDate =
    debouncedSearchValue === searchValue && !isFetchingIngredients && !isFetchingRecipes;

  const allOptions: RecipeFormIngredient[] = useMemo(
    () => [
      ...(ingredients.map((ingredient) => ({
        id: null,
        name: ingredient?.name,
        refId: ingredient.id,
        isIngredientRecipe: false,
        measurements: [],
        notes: '',
      })) as RecipeFormIngredient[]),
      ...(recipes.map((recipe) => ({
        id: null,
        name: recipe.name,
        refId: recipe.id,
        isIngredientRecipe: true,
        measurements: [],
        notes: '',
      })) as RecipeFormIngredient[]),
    ],
    [ingredients, recipes],
  );

  const options: RecipeFormIngredient[] = useMemo(() => {
    let filteredOptions = allOptions;
    if (
      searchValue !== '' &&
      !filteredOptions.find((i) => i.name.trim().toLowerCase() === searchValue.trim().toLowerCase())
    ) {
      const newIngredient = {
        id: null,
        refId: null,
        name: searchValue,
        isIngredientRecipe: false,
        measurements: [],
        notes: '',
      };

      filteredOptions = [
        { ...newIngredient, type: NewIngredientType.Ingredient },
        { ...newIngredient, type: NewIngredientType.MEP },
        ...filteredOptions,
      ];
    }

    return uniq(
      filteredOptions
        .filter((item) => !selectedIngredients.map((i) => i?.name).includes(item.name))
        .filter((item) => !!recipeId && item.refId !== Number(recipeId))
        .sort((a) => (a.refId === null ? -1 : 1)),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOptions, searchValue, selectedIngredients, recipeId, onChange]);

  const isFetchingNextPage = isFetchingNextIngredientsPage || isFetchingNextRecipesPage;
  const getNext = (inView: boolean) => {
    if (inView && !isFetchingNextPage) {
      if (hasNextIngredientsPage && !isFetchingNextIngredientsPage) fetchNextIngredientsPage();
      if (hasNextRecipesPage && !isFetchingNextRecipesPage) fetchNextRecipesPage();
    }
  };

  const handleClickOption = (selectedOption: RecipeFormIngredient | string | null) => {
    // when clearing value or pressing enter
    if (selectedOption === null) {
      return onChange(null);
    }
    // pressing enter
    if (typeof selectedOption === 'string') {
      if (!areResultsUpToDate) return;
      const trimmedValue = selectedOption.trim();
      const existingOption = options.find(
        (option) => option.name.toLowerCase() === trimmedValue.toLowerCase(),
      );
      if (existingOption) {
        onChange(existingOption);
      } else {
        if (!!trimmedValue) {
          handleCreateIngredient(trimmedValue);
        }
      }
      return;
    }
    // when creating new option
    if (selectedOption.type === NewIngredientType.Ingredient) {
      return handleCreateIngredient(selectedOption.name);
    }
    if (selectedOption.type === NewIngredientType.MEP) {
      return handleCreateMEP(selectedOption.name);
    }
    // when selecting existing option
    onChange(selectedOption);
  };

  const handleCreateIngredient = async (name: string) => {
    const newIngredient: RecipeFormIngredient = {
      name,
      refId: null,
      isIngredientRecipe: false,
      measurements: [],
      notes: '',
    };

    onChange(newIngredient);

    createIngredient(
      { name, notes: '' },
      {
        onSuccess: ({ data }) => {
          onChange({
            ...newIngredient,
            refId: data.id,
            measurements: [
              {
                recipeIngredientId: data.id,
                id: null,
                quantity: '',
                unit: '',
              },
              {
                recipeIngredientId: null,
                id: null,
                quantity: '',
                unit: '',
              },
            ],
          });
        },
      },
    );
  };

  const handleCreateMEP = (name: string) => {
    const newIngredient = {
      name,
      id: null,
      refId: null,
      isIngredientRecipe: true,
      measurements: [],
      notes: '',
    };

    onChange(newIngredient);

    createRecipe(
      { name, type: RecipeType.MiseEnPlace },
      {
        onSuccess: ({ data }) => {
          onChange({
            ...newIngredient,
            refId: data.id,
            measurements: [
              {
                recipeIngredientId: data.id,
                id: null,
                quantity: '',
                unit: '',
              },
              {
                recipeIngredientId: null,
                id: null,
                quantity: '',
                unit: '',
              },
            ],
          });

          notifications.success({
            message: intl.formatMessage({ id: 'recipes.mep.added' }),
            cta: intl.formatMessage({ id: 'recipes.recipe.added.view' }),
            onClick: () =>
              navigate(generatePath(Routes.RecipeDetail, { recipeId: String(data.id) })),
          });
        },
      },
    );
  };

  if (isReadOnly) {
    const name = isShowingTranslation ? translatedName : value?.name || '-';

    if (
      value?.isIngredientRecipe &&
      (isHQ || value.status === RecipeStatus.Published || value.country === user?.group.country)
    ) {
      return (
        <Link
          to={generatePath(Routes.RecipeDetail, { recipeId: `${value?.refId}` })}
          target="_blank"
        >
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.global02[700],
              textDecoration: 'underline',
              transition: 'color 0.2s',
              '&:hover': {
                color: (theme) => theme.palette.primary.dark,
              },
            }}
          >
            {name}
          </Typography>
        </Link>
      );
    }

    return <Typography variant="body2">{name}</Typography>;
  }

  const searchInputMatchesOption = options.find((option) => option.name === searchValue);

  return (
    <Autocomplete
      value={value}
      inputValue={searchValue}
      options={options}
      onOpen={() => setFetchEnabled(true)}
      onClose={() => setFetchEnabled(false)}
      clearOnEscape
      selectOnFocus
      freeSolo
      handleHomeEndKeys
      clearOnBlur={!searchInputMatchesOption?.refId}
      onBlur={
        searchInputMatchesOption?.refId ? () => handleClickOption(searchInputMatchesOption) : noop
      }
      blurOnSelect
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      onChange={(_, val) => handleClickOption(val)}
      onInputChange={(_, value) => handleSearchChange(value)}
      loading={!areResultsUpToDate}
      sx={{
        '@media print': {
          [`& .${autocompleteClasses.input}`]: {
            textOverflow: 'unset',
            padding: '0 4px !important',
          },
          [`& .${outlinedInputClasses.root}`]: { padding: '0 !important' },
          [`& .${autocompleteClasses.inputRoot}`]: { padding: '0 !important' },
          [`& .${outlinedInputClasses.notchedOutline}`]: { border: 'none' },
        },
      }}
      ListboxProps={{
        role: 'list-box',
        sx: { [`& .${buttonBaseClasses.root}`]: { pl: 2, pr: 1, py: 1 } },
      }}
      style={{ width: '100%' }} // not in sx because then it doesn't end up on the correct element
      slotProps={{
        popper: { popperOptions: { placement: 'bottom-start' }, sx: { width: '360px !important' } },
      }}
      clearIcon={<CloseIcon sx={{ width: 16, height: 16 }} />}
      componentsProps={{
        clearIndicator: {
          sx: {
            mr: 0.25,
            mb: 0.25,
            height: 20,
            width: 20,
            alignItems: 'center',
            justifyContent: 'center',
            display: value?.name || debouncedSearchValue ? 'flex' : 'none',
          },
          onClick: () => {
            handleSearchChange('');
            onChange(null);
          },
        },
        paper: { sx: { width: 320 } },
        popper: { popperOptions: { placement: 'bottom-start' } },
      }}
      loadingText={Array.from({ length: 10 }).map((_, index) => (
        <IngredientItemSkeleton key={index} />
      ))}
      renderOption={(props, option, state) => {
        // if loading, we don't show the "create new ingredient" option
        if (!option.refId && !areResultsUpToDate) return <IngredientItemSkeleton />;
        // create new ingredient options
        if (option.refId === null) {
          if (option.type === NewIngredientType.Ingredient) {
            return (
              <MenuItem
                data-testid={option.name}
                sx={menuItemSx}
                {...props}
                key={`new_ingredient_${option.name}`}
              >
                <AutocompleteAddItem
                  icon={<PlaylistAddRoundedIcon sx={{ color: 'black.60', width: 24 }} />}
                  label={<FormattedMessage id="recipes.ingredients.add_new" />}
                  name={option.name}
                />
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                data-testid={option.name}
                sx={menuItemSx}
                {...props}
                key={`new_mep_${option.name}`}
              >
                <AutocompleteAddItem
                  icon={<QueueRoundedIcon sx={{ color: 'black.60', width: 24 }} />}
                  label={<FormattedMessage id="recipes.mep.add_new" />}
                  name={option.name}
                />
              </MenuItem>
            );
          }
        }
        // existing item
        return (
          <MenuItem
            data-testid={option.name}
            sx={{ ...menuItemSx }}
            {...props}
            key={`existing_${option.refId}`}
          >
            <AutocompleteItem inputValue={searchValue} option={option} />
            {state.index === options.length - 1 && <InView as="div" onChange={getNext} />}
          </MenuItem>
        );
      }}
      renderInput={({ inputProps, ...props }) => (
        <TextField
          variant="outlined"
          placeholder={intl.formatMessage({ id: 'general.label.name' })}
          sx={{
            [`& .${outlinedInputClasses.root}`]: {
              p: 0,
            },
          }}
          inputProps={{
            maxLength: 255,
            'data-testid': `ingredient-${index}-name`,
            ...inputProps,
          }}
          {...props}
        />
      )}
    />
  );
};

export default memo(IngredientAutocomplete);

const menuItemSx = {
  px: 2,
  py: 1.5,
  gap: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
};

const IngredientItemSkeleton = () => (
  <Stack
    direction="row"
    px={2}
    py={1.5}
    width="100%"
    alignItems="center"
    justifyContent="space-between"
  >
    <Skeleton height={14} width={160} />
    <Skeleton height={14} width={40} />
  </Stack>
);
