import { Skeleton, Stack } from '@mui/material';

const OptionSkeleton = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height={48}
      px={2}
      py={1.5}
    >
      <Skeleton height={24} width="30%" />
      <Skeleton height={24} width={40} variant="circular" />
    </Stack>
  );
};

export default OptionSkeleton;
