import { Search as SearchIcon } from '@mui/icons-material';
import { Box, Collapse, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { RESULTS_PER_PAGE } from 'constants/common.constants';
import { useSearchState } from 'hooks';
import { useRecipes, useRecommendedRecipes, useUser } from 'queries';
import { RecipePreview, RecipeStatus, RecipeTableColumns } from 'types/recipes.types';

import {
  CreateRecipeButton,
  PurchasedItemButton,
} from 'components/@menu-form/MenuItemDrawer/ActionButtons';

import RecipeList from './RecipeList';

interface Props {
  menuId: number | undefined;
  menuItemId: number | undefined;
  isCreatingRecipe: boolean;
  isCreatingPurchasedItem: boolean;
  isUpdatingMenuItem: boolean;
  handleCreateRecipe: () => void;
  handlePurchasedItem: () => void;
  handleSelectRecipe: (recipe: RecipePreview, params?: { silent?: boolean }) => void;
}

const MenuItemRecipeList = ({
  menuId,
  menuItemId,
  isCreatingRecipe,
  isCreatingPurchasedItem,
  isUpdatingMenuItem,
  handleCreateRecipe,
  handlePurchasedItem,
  handleSelectRecipe,
}: Props) => {
  const intl = useIntl();
  const { user, isLoading: isLoadingUser } = useUser();
  const { searchValue, debouncedSearchValue, handleSearchChange } = useSearchState();

  const { data: recommendedRecipes, isFetching: isLoadingRecommendedRecipes } =
    useRecommendedRecipes(
      { menuId: menuId || 0, menuItemId: menuItemId || 0 },
      { enabled: !!menuId && !!menuItemId },
    );

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    data: recipes,
    isLoading,
    isFetching,
  } = useRecipes(
    {
      search: debouncedSearchValue,
      page_size: RESULTS_PER_PAGE,
      status: [
        RecipeStatus.Draft,
        RecipeStatus.Submitted,
        RecipeStatus.Rejected,
        RecipeStatus.Approved,
        RecipeStatus.Published,
      ],
      sort: `-${RecipeTableColumns.UpdatedAt}`,
    },
    { enabled: !!user },
  );

  return (
    <Box height="100svh" display="flex" flexDirection="column" width="100%" overflow="hidden">
      <Stack gap={4} p={3}>
        <Typography variant="h6" fontWeight={400}>
          <FormattedMessage id="menus.matcher.recipe_list.title" />
        </Typography>

        <TextField
          variant="standard"
          placeholder={intl.formatMessage({ id: 'menus.matcher.recipe_list.title' })}
          value={searchValue}
          onChange={(e) => handleSearchChange(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ width: 24 }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Box sx={{ overflowX: 'hidden', overflowY: 'auto' }}>
        <Collapse in={!debouncedSearchValue}>
          <RecipeList
            title="menus.items.recipes.top_hit"
            recipes={recommendedRecipes}
            isLoading={isLoadingUser || isLoadingRecommendedRecipes}
            loadingItems={1}
            isUpdatingMenuItem={isUpdatingMenuItem}
            onSelectRecipe={handleSelectRecipe}
          />
        </Collapse>

        <RecipeList
          title="recipes.label"
          recipes={recipes}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoadingUser || isLoading || isFetching}
          hasSearchValue={!!debouncedSearchValue}
          isUpdatingMenuItem={isUpdatingMenuItem}
          fetchNextPage={fetchNextPage}
          onSelectRecipe={handleSelectRecipe}
        />
      </Box>

      <Box mt="auto" display="flex" gap={2} px={3} py={2}>
        <CreateRecipeButton
          loading={isCreatingRecipe || isUpdatingMenuItem}
          disabled={isCreatingPurchasedItem || isUpdatingMenuItem}
          wide
          onClick={handleCreateRecipe}
        />
        <PurchasedItemButton
          loading={isCreatingPurchasedItem || isUpdatingMenuItem}
          disabled={isCreatingRecipe || isUpdatingMenuItem}
          wide
          onClick={handlePurchasedItem}
        />
      </Box>
    </Box>
  );
};

export default MenuItemRecipeList;
