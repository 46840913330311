import { Check as CheckIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { ButtonWithMenu } from 'components/@common/ButtonWithMenu';

type Props = {
  isHQ: boolean;
  onUnpublish: () => void;
  isLoading: boolean;
};

const RecipePublishedButton = ({ isHQ, onUnpublish, isLoading }: Props) => {
  if (isHQ) {
    return (
      <ButtonWithMenu
        variant="outlined"
        testId="published-recipe-menu-button"
        buttonText={<FormattedMessage id="recipes.creator.button.franchisee.published" />}
        color="secondary"
        loading={isLoading}
        startIcon={<CheckIcon />}
        menuItems={[
          {
            label: <FormattedMessage id="recipes.creator.button.hq.unpublish" />,
            icon: <VisibilityOffIcon />,
            onClick: onUnpublish,
            action: 'unpublish',
          },
        ]}
      />
    );
  }
  return (
    <Button
      startIcon={<CheckIcon />}
      data-testid="published-recipe-button"
      variant="outlined"
      color="secondary"
      sx={{ cursor: 'default' }}
    >
      <FormattedMessage id="recipes.creator.button.franchisee.published" />
    </Button>
  );
};

export default RecipePublishedButton;
