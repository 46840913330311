import { Stack, TableRow, Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { TranslatedRecipeFormIngredient } from 'types/recipe-translations.types';
import { RecipeFormIngredient } from 'types/recipes.types';

import { TableCell, TranslationsTable } from '../TranslationsTable';
import IngredientTranslation from './IngredientTranslation';

type Props = {
  targetLocale: string;
  isLoading?: boolean;
};

const IngredientsTranslationsTable = ({
  targetLocale = DEFAULT_TRANSLATION_LOCALE,
  isLoading,
}: Props) => {
  const ingredients = useWatch({ name: 'ingredients' }) as RecipeFormIngredient[];
  const translatedIngredients = useWatch({
    name: `translation.ingredients`,
  }) as TranslatedRecipeFormIngredient[];

  return (
    <TranslationsTable isLoading={isLoading} targetLocale={targetLocale}>
      {ingredients?.map((item, index) => {
        const translatedIngredient = translatedIngredients?.find(
          (val) => val.refId === item.refId && val.isIngredientRecipe === item.isIngredientRecipe,
        );
        if (!translatedIngredient) return null;
        return (
          <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>
              <Stack gap={1}>
                <Typography variant="body1">{item.name}</Typography>
                {!!translatedIngredient?.note.note && (
                  <Typography variant="body1" color="black.60">
                    {translatedIngredient.note.note}
                  </Typography>
                )}
              </Stack>
            </TableCell>
            <TableCell>
              <IngredientTranslation ingredient={translatedIngredient} index={index} />
            </TableCell>
          </TableRow>
        );
      })}
    </TranslationsTable>
  );
};

export default IngredientsTranslationsTable;
