import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import config from 'config';

import { isDev } from 'utils/env.utils';

interface TranslationLocaleState {
  translationLocale: string;
  setTranslationLocale: (newTranslationLocale: string) => void;
}

/**
 * Store to persist the current translation locale
 */
const translationLocaleStore = create<TranslationLocaleState>()(
  devtools(
    persist(
      (set) => ({
        translationLocale: config.DEFAULT_TRANSLATION_LOCALE,
        setTranslationLocale: (newTranslationLocale) =>
          set(() => ({ translationLocale: newTranslationLocale })),
      }),
      {
        name: 'translation-locale-store',
      },
    ),
    {
      enabled: isDev(),
    },
  ),
);

export const useTranslationLocale = () => translationLocaleStore((state) => state);
