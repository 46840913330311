import { Avatar, Collapse, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import alain_avatar from 'assets/images/avatar_alain.png';

type Props = {
  compact?: boolean;
};

const ChatHeader = ({ compact }: Props) => (
  <Stack
    color="bluegrey.50"
    justifySelf="flex-start"
    width="100%"
    position={compact ? 'sticky' : 'relative'}
    top={0}
    zIndex={1}
  >
    <Stack
      px={5}
      py={compact ? 2 : 4}
      direction="row"
      gap={3}
      alignItems="center"
      bgcolor="bluegrey.900"
      sx={{ transition: 'all 0.3s ease' }}
    >
      <Avatar
        src={alain_avatar}
        sx={{ height: compact ? 56 : 72, width: compact ? 56 : 72, transition: 'all 0.3s ease' }}
      />
      <Stack>
        <Typography variant="h6">
          <FormattedMessage id="chat.header.label" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id="chat.header.description" />
        </Typography>
      </Stack>
    </Stack>
    <Collapse in={!compact}>
      <Typography p={5} variant="body1" fontStyle="italic" fontSize="1.5rem" fontWeight={300}>
        <FormattedMessage id="chat.message.initial" />
      </Typography>
    </Collapse>
  </Stack>
);
export default ChatHeader;
