import { Box, Fade, Skeleton, Stack, Typography } from '@mui/material';
import { memo, useRef } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { StorageMediaContext } from 'types/media.types';
import { RecipeFormValues } from 'types/recipes.types';

import { DropZone } from 'components/@form';

import { DescriptionTextField } from './PreparationItem.style';

type Props = {
  index: number;
  recipeId: number;
  isReadOnly: boolean;
  isShowingTranslation: boolean;
  isLoadingTranslation: boolean;
};

const PreparationItem = ({
  recipeId,
  index,
  isReadOnly,
  isShowingTranslation,
  isLoadingTranslation,
}: Props) => {
  const intl = useIntl();
  const { control } = useFormContext<RecipeFormValues>();
  const dropZoneRef = useRef<{ addFiles: (files: File[]) => void }>(null);

  const value = useWatch<RecipeFormValues>({
    name: `preparationSteps.${index}`,
  }) as RecipeFormValues['preparationSteps'][0];

  const translatedValue = useWatch({
    name: `translation.preparationSteps[${index}].translation.description`,
  });

  if (isReadOnly && !value.description && !value.image) return null;

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const file = event.clipboardData?.files?.item(0);
    if (!file) return;

    event.preventDefault();
    event.stopPropagation();
    dropZoneRef.current?.addFiles([file]);
  };

  return (
    <>
      <Fade in easing="ease-in" timeout={300}>
        <Box pr={1}>
          <DropZone
            name={`preparationSteps.${index}.image`}
            testId={`prep${index + 1}-img-input`}
            storageContext={StorageMediaContext.RecipeStep}
            storageFolder={`${recipeId}`}
            bgcolor="black.4"
            height={204}
            disabled={isReadOnly}
            ref={dropZoneRef}
            tabIndex={-1}
          />
        </Box>
      </Fade>

      <Stack direction="row" gap={1}>
        <Typography className="print-only" fontSize={24} fontWeight="900 !important">
          {index + 1}
        </Typography>
        <Controller
          control={control}
          name={`preparationSteps.${index}.description`}
          render={({ field: { onChange, value } }) => {
            if (isLoadingTranslation) {
              return <Skeleton height={16} width="75%" />;
            }

            if (isReadOnly) {
              return (
                <Typography
                  variant="body2"
                  whiteSpace="pre-wrap"
                  textOverflow="revert"
                  maxWidth="100%"
                  sx={{
                    lineBreak: 'loose',
                    wordBreak: 'break-word',
                    color: (theme) => theme.palette.black[90],
                  }}
                >
                  {isShowingTranslation ? translatedValue : value}
                </Typography>
              );
            }

            return (
              <DescriptionTextField
                value={value}
                multiline
                placeholder={intl.formatMessage({
                  id: 'recipes.preparation.steps.description',
                })}
                onChange={onChange}
                inputProps={{
                  maxLength: 1024,
                  'data-testid': `prep${index + 1}-description-input`,
                }}
                onPaste={handlePaste}
              />
            );
          }}
        />
      </Stack>
    </>
  );
};

export default memo(PreparationItem);
