import { ContentCopy as ContentCopyIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'constants/routes.constants';
import { useIsHQ } from 'hooks/useIsHQ';
import { useArchiveRecipe, useCreateRecipeVariant } from 'queries';
import { RecipeFormValues, RecipeStatus } from 'types/recipes.types';

import { ConfirmationDialog } from 'components/@common';
import ActionMenu from 'components/@common/ActionMenu/ActionMenu';
import ActionMenuItem from 'components/@common/ActionMenu/ActionMenuItem';

const RecipeActionsMenu = () => {
  const intl = useIntl();
  const posthog = usePostHog();
  const { getValues } = useFormContext<RecipeFormValues>();
  const { createRecipeVariant, isPending: isCreatingRecipeVariant } = useCreateRecipeVariant();
  const { deleteRecipe, isPending: isDeletingRecipe } = useArchiveRecipe();
  const isHQ = useIsHQ();

  const navigate = useNavigate();

  const status = useWatch<RecipeFormValues>({ name: 'status' }) as RecipeFormValues['status'];

  const handleCreateRecipeVariant = () => {
    const formValues = getValues();

    posthog.capture('create_recipe', {
      type: 'variant',
      source: formValues.status === RecipeStatus.Published ? 'cookbook' : 'experimental_kitchen',
      name: formValues.name,
    });

    createRecipeVariant(formValues);
  };

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const handleDeleteRecipe = () => {
    deleteRecipe(getValues('id'), {
      onSuccess: () =>
        navigate(status === RecipeStatus.Published ? Routes.Cookbook : Routes.ExperimentalKitchen, {
          replace: true,
        }),
    });

    setOpenConfirmDelete(false);
  };

  return (
    <Box className="no-print">
      <ActionMenu>
        <ActionMenuItem
          Icon={ContentCopyIcon}
          onClick={handleCreateRecipeVariant}
          isLoading={isCreatingRecipeVariant}
          label={<FormattedMessage id="recipes.actions.variant.create" />}
        />
        {status !== RecipeStatus.Archived &&
          (isHQ || [RecipeStatus.Draft, RecipeStatus.Rejected].includes(status)) && (
            <ActionMenuItem
              Icon={DeleteIcon}
              isLoading={isDeletingRecipe}
              onClick={() => setOpenConfirmDelete(true)}
              label={
                <FormattedMessage
                  id="general.actions.archive"
                  values={{ type: intl.formatMessage({ id: 'recipes.label.singular' }) }}
                />
              }
            />
          )}
      </ActionMenu>

      <ConfirmationDialog
        title={
          <FormattedMessage
            id="general.actions.archive.confirm.title"
            values={{ type: intl.formatMessage({ id: 'recipes.label.singular' }) }}
          />
        }
        message={<FormattedMessage id="general.actions.delete.confirm.text" />}
        onConfirm={handleDeleteRecipe}
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
      />
    </Box>
  );
};

export default RecipeActionsMenu;
