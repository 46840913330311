import { useQuery } from '@tanstack/react-query';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { Duration } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { CommonQueryOptions } from 'queries/types';
import { apiService } from 'services';

const fetchLanguages = async () => {
  const { data: languages } = await apiService.getLanguages();
  return languages;
};

export const LanguageQuery = {
  queryFn: fetchLanguages,
  queryKey: QueryKeys.languages.all(),
  staleTime: Duration.FIVE_MIN,
};

export const useLanguages = (
  config: CommonQueryOptions<{ data: string[] }, ReturnType<typeof QueryKeys.languages.all>> = {},
) => {
  const { isLoading, isError, data, error } = useQuery({
    ...LanguageQuery,
    ...config,
    select: ({ data }) =>
      data.sort((a, b) => {
        if (a === DEFAULT_TRANSLATION_LOCALE) return -1;
        if (b === DEFAULT_TRANSLATION_LOCALE) return 1;
        return a > b ? 1 : -1;
      }),
  });

  return {
    languages: data || [],
    isLoading,
    isError,
    error,
  };
};
