import { useCallback, useMemo } from 'react';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import {
  RecipeTranslationMethod,
  RecipeTranslationStatus,
  TranslatedIngredient,
  TranslatedRecipeFormIngredient,
  TranslatedRecipeIngredient,
} from 'types/recipe-translations.types';

export const getLanguageKey = (language?: string) =>
  `language.${language || DEFAULT_TRANSLATION_LOCALE}` as I18nKey;

export const getVerifiedState = () =>
  // recipes: RecipeDetailed[],
  // totalMenuItems: number,
  // language: string,
  {
    //@TODO: calculate verified percentage
    // const translatedRecipes = recipes.filter((recipe) =>
    //   recipe.translations?.some((translation) => translation.language === language),
    // );

    // const verifiedRecipes = translatedRecipes.filter((recipe) =>
    //   recipe.translations?.some(
    //     (translation) =>
    //       translation.language === language &&
    //       translation.status === RecipeTranslationStatus.Verified,
    //   ),
    // );

    // return {
    //   verifiedStatus: !!verifiedRecipes.length
    //     ? RecipeTranslationStatus.Verified
    //     : RecipeTranslationStatus.AI,
    //   verifiedPercentage: (verifiedRecipes.length / totalMenuItems) * 100,
    //   recipesToTranslate: recipes.filter((recipe) => !translatedRecipes.includes(recipe)),
    // };
    return {
      verifiedStatus: RecipeTranslationStatus.AI,
      verifiedPercentage: 70,
      recipesToTranslate: [],
    };
  };

/**
 * Hook to format recipe translations for the form
 * - We need this formatter because the api response returns each ingredient twice
 * (One for each measurement)
 */
export const useTransformIngredientTranslations = () => {
  const transformApiIngredientTranslationsToForm = useCallback(
    (ingredients: TranslatedRecipeIngredient[]): TranslatedRecipeFormIngredient[] => {
      return ingredients.reduce<TranslatedRecipeFormIngredient[]>((acc, ingredient) => {
        const isRecipe = !!ingredient.isIngredientRecipe;

        const translatedName = String(
          isRecipe
            ? (ingredient.recipe?.translation.name ?? ingredient.recipe?.name)
            : (ingredient.ingredient?.translation.name ?? ingredient.ingredient?.name),
        );

        const transformedIngredient: TranslatedRecipeFormIngredient = {
          isIngredientRecipe: isRecipe,
          refId: Number(isRecipe ? ingredient.recipe?.id : ingredient.ingredient?.id),
          name: (isRecipe ? ingredient.recipe?.name : ingredient.ingredient?.name) || '',
          translatedName,
          translationId: Number(
            isRecipe ? ingredient.recipe?.translation.id : ingredient.ingredient?.translation.id,
          ),
          note: {
            note: ingredient.notes,
            method: ingredient.translation.fieldTranslationMethods.notes,
            translatedNote: ingredient.translation.notes || '',
          },
          method: isRecipe
            ? RecipeTranslationMethod.MEP
            : (ingredient.ingredient?.translation?.fieldTranslationMethods?.name ??
              RecipeTranslationMethod.AI),
        };

        // Check for uniqueness before adding to the accumulator
        const isUnique = !acc.some(
          (existingIngredient) =>
            existingIngredient.translationId === transformedIngredient.translationId &&
            existingIngredient.isIngredientRecipe === transformedIngredient.isIngredientRecipe,
        );

        if (isUnique) {
          acc.push(transformedIngredient);
        }

        return acc;
      }, []);
    },
    [],
  );

  const transformFormIngredientTranslationsToApi = useCallback(
    (
      ingredients: TranslatedRecipeFormIngredient[],
      initialIngredients: TranslatedRecipeIngredient[],
    ): TranslatedRecipeIngredient[] => {
      return initialIngredients.reduce<TranslatedRecipeIngredient[]>((acc, ingredient) => {
        const isRecipe = !!ingredient.isIngredientRecipe;
        const ingredientData = ingredients.find((i) =>
          isRecipe
            ? i.translationId === ingredient.recipe?.translation.id
            : i.translationId === ingredient.ingredient?.translation.id,
        );
        const transformedIngredient: TranslatedRecipeIngredient = {
          ...ingredient,
          recipe: isRecipe
            ? transformTranslatedIngredientToApi(ingredient.recipe, ingredientData)
            : null,
          ingredient: isRecipe
            ? null
            : transformTranslatedIngredientToApi(ingredient.ingredient, ingredientData),
          notes: ingredientData?.note.note || ingredient.notes,
          translation: {
            ...ingredient.translation,
            notes: ingredientData?.note.translatedNote || ingredient.translation.notes,
            fieldTranslationMethods: {
              ...ingredient.translation.fieldTranslationMethods,
              notes: ingredientData?.note.method ?? RecipeTranslationMethod.AI,
            },
          },
        };

        acc.push(transformedIngredient);
        return acc;
      }, []);
    },
    [],
  );

  const returnValue = useMemo(
    () => ({ transformApiIngredientTranslationsToForm, transformFormIngredientTranslationsToApi }),
    [transformApiIngredientTranslationsToForm, transformFormIngredientTranslationsToApi],
  );
  return returnValue;
};

const transformTranslatedIngredientToApi = (
  ingredient: TranslatedIngredient | null,
  initialIngredient?: TranslatedRecipeFormIngredient,
) => {
  if (!ingredient?.translation) {
    return ingredient;
  }
  return {
    ...ingredient,
    translation: {
      ...ingredient?.translation,
      fieldTranslationMethods: {
        ...ingredient?.translation.fieldTranslationMethods,
        name: initialIngredient?.method || RecipeTranslationMethod.AI,
      },
      name: String(initialIngredient?.translatedName),
    },
  };
};
