import {
  Divider,
  Paper,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';

type Props = {
  targetLocale: string;
  isLoading?: boolean;
  children?: ReactNode;
  prependColumn?: {
    title: I18nKey;
    loadingContent?: ReactNode;
  };
};

const TranslationsTable = ({ targetLocale, isLoading, children, prependColumn }: Props) => {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table>
        <TableHead>
          <TableRow>
            {prependColumn && (
              <Cell sx={{ width: '20%' }}>
                <FormattedMessage id={prependColumn.title} />
              </Cell>
            )}
            <Cell sx={{ width: !!prependColumn ? '40%' : '50%' }}>
              <FormattedMessage id={`language.${DEFAULT_TRANSLATION_LOCALE}` as I18nKey} />
            </Cell>
            <Cell sx={{ width: !!prependColumn ? '40%' : '50%' }}>
              <FormattedMessage id={`language.${targetLocale}` as I18nKey} />
            </Cell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading &&
            Array.from({ length: 2 }).map((_, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {prependColumn?.loadingContent && <Cell>{prependColumn.loadingContent}</Cell>}
                <Cell>
                  <Skeleton sx={{ my: 3 }} height={16} width="75%" />
                </Cell>
                <Cell>
                  <Stack gap={1} mt={0.5}>
                    <Skeleton sx={{ mx: 1.5 }} height={16} width={200} />
                    <Divider />
                    <Skeleton sx={{ mx: 1.5 }} height={12} width={150} />
                  </Stack>
                </Cell>
              </TableRow>
            ))}
          {!isLoading && children}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TranslationsTable;

export const Cell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(3),
  [`&:first-of-type`]: { paddingRight: 0 },
  [`&:last-of-type`]: { paddingLeft: 0 },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.bluegrey[50],
    color: theme.palette.common.black,
    ...theme.typography.button,
    textTransform: 'uppercase',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
