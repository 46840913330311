import {
  autocompleteClasses,
  Box,
  outlinedInputClasses,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { lineClamp } from 'utils/text.utils';

import { RecipeFormIngredient, RecipeFormMeasurement, RecipeFormValues } from 'types/recipes.types';

import { InputSelectCombo } from 'components/@form';
import { IngredientAutocomplete } from 'components/@recipe-form/IngredientAutocomplete';

type Props = {
  index: number;
  isReadOnly: boolean;
  isShowingTranslation: boolean;
  isLoadingTranslations: boolean;
  ingredient: RecipeFormIngredient;
};

const IngredientItem = ({
  index,
  isReadOnly,
  isShowingTranslation,
  isLoadingTranslations,
  ingredient,
}: Props) => {
  const intl = useIntl();
  const { control, watch } = useFormContext<RecipeFormValues>();

  const firstMeasurement = watch(`ingredients.${index}.measurements.0`) as RecipeFormMeasurement;
  const secondMeasurement = watch(`ingredients.${index}.measurements.1`) as RecipeFormMeasurement;

  const hasFirstMeasurement = firstMeasurement?.quantity || firstMeasurement?.quantity;
  const hasSecondMeasurement = secondMeasurement?.quantity || secondMeasurement?.quantity;

  const translatedData = watch('translation.ingredients')?.find(
    (val) =>
      val.refId === ingredient.refId && val.isIngredientRecipe === ingredient.isIngredientRecipe,
  );

  return (
    <Box display="flex" gap={2}>
      <Box flex={1} display="flex" alignItems="center">
        <Controller
          control={control}
          name={`ingredients.${index}`}
          render={({ field: { onChange, value } }) => {
            if (isLoadingTranslations) {
              return <Skeleton height={16} width="50%" />;
            }

            return (
              <IngredientAutocomplete
                index={index + 1}
                onChange={(newValue) =>
                  onChange({
                    ...value,
                    name: newValue?.name || '',
                    refId: newValue?.refId || null,
                    isIngredientRecipe: newValue?.isIngredientRecipe || false,
                    status: newValue?.status || undefined,
                    country: newValue?.country || undefined,
                  })
                }
                value={value}
                translatedName={translatedData?.translatedName}
                isShowingTranslation={isShowingTranslation}
                isReadOnly={isReadOnly}
              />
            );
          }}
        />
      </Box>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        maxWidth="50%"
        gap={!isReadOnly || (hasFirstMeasurement && hasSecondMeasurement) ? 1 : 0}
        flex={2}
        sx={(theme) => ({ [theme.breakpoints.down('lg')]: { flex: 2 } })}
      >
        <Box flex={isReadOnly ? undefined : 1}>
          <Controller
            control={control}
            name={`ingredients.${index}.measurements.0`}
            render={({ field: { ref, ...field }, ...state }) => {
              return (
                <InputSelectCombo
                  index={index + 1}
                  order={1}
                  {...field}
                  {...state.fieldState}
                  {...state.formState}
                  label={intl.formatMessage({ id: 'recipes.ingredients.quantity.label' })}
                  isReadOnly={isReadOnly}
                />
              );
            }}
          />
        </Box>
        {isReadOnly && !!hasSecondMeasurement && !!hasFirstMeasurement ? (
          <Typography variant="body2">/</Typography>
        ) : null}
        <Box flex={isReadOnly ? undefined : 1}>
          <Controller
            control={control}
            name={`ingredients.${index}.measurements.1`}
            render={({ field: { ref, ...field }, ...state }) => (
              <InputSelectCombo
                index={index + 1}
                order={2}
                {...field}
                {...state.fieldState}
                {...state.formState}
                label={intl.formatMessage({ id: 'recipes.ingredients.quantity.label' })}
                isReadOnly={isReadOnly}
              />
            )}
          />
        </Box>
      </Stack>

      <Box flex={1} display="flex" alignItems="center">
        <Controller
          control={control}
          name={`ingredients.${index}.notes`}
          render={({ field }) =>
            isReadOnly ? (
              <Typography
                variant="body2"
                sx={{ maxWidth: 200, wordBreak: 'break-word', ...lineClamp(2) }}
              >
                {isShowingTranslation ? translatedData?.note.translatedNote : field.value}
              </Typography>
            ) : (
              <TextField
                {...field}
                placeholder={intl.formatMessage({ id: 'recipes.ingredients.add.remarks' })}
                size="small"
                inputProps={{
                  maxLength: 255,
                  'data-testid': `ingredient${index + 1}-notes`,
                }}
                sx={{
                  '@media print': {
                    [`& .${autocompleteClasses.input}`]: { textOverflow: 'unset' },
                    [`& .${outlinedInputClasses.notchedOutline}`]: { border: 'none' },
                  },
                }}
              />
            )
          }
        />
      </Box>
    </Box>
  );
};

export default memo(IngredientItem);
