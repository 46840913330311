import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useRecipeComplianceRules } from 'queries';

type Props = {
  showExplanation: boolean;
  onShowExplanationChange: (show: boolean) => void;
};

const OnBrandnessExplanationDialog = ({ showExplanation, onShowExplanationChange }: Props) => {
  const { data: rules, isLoading } = useRecipeComplianceRules();

  // separate the even and odd indexes into two columns
  const ingredients = useMemo<{ odd: string[]; even: string[] }>(() => {
    const odd: string[] = [];
    const even: string[] = [];
    rules?.keywords.forEach((ingredient, index) => {
      if (index % 2 === 0) {
        odd.push(ingredient);
      } else {
        even.push(ingredient);
      }
    });
    return { odd, even };
  }, [rules?.keywords]);

  return (
    <Dialog open={showExplanation} onClose={() => onShowExplanationChange(false)}>
      <DialogTitle display="flex" justifyContent="space-between" alignItems="center" gap={4}>
        <FormattedMessage id="recipes.props.onbrand.explanation.title" />
        <Button variant="text" color="inherit" onClick={() => onShowExplanationChange(false)}>
          <FormattedMessage id="common.close" />
        </Button>
      </DialogTitle>
      {isLoading ? (
        <Stack justifyContent="center" alignItems="center" m={5}>
          <CircularProgress size={48} />
        </Stack>
      ) : (
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 5, mt: 2, mb: 6 }}>
          <Stack gap={1}>
            <Typography fontWeight={700}>
              1.&nbsp;
              <FormattedMessage id="recipes.props.onbrand.explanation.content.ingredient_amount.title" />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage id="recipes.props.onbrand.explanation.content.ingredient_amount.description" />
            </Typography>

            <List sx={{ listStyle: 'disc' }} dense disablePadding>
              {rules?.ingredientRules.map(({ rangeMin, rangeMax, deduction }, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  dense
                  sx={(theme) => ({
                    display: 'list-item',
                    listStylePosition: 'inside',
                    fontStyle: 'italic',
                    ...theme.typography.caption,
                  })}
                >
                  {rangeMin}
                  {!!rangeMax && rangeMax !== 'Infinity' ? `-${rangeMax}` : '+'}{' '}
                  <FormattedMessage id="recipes.ingredients.label" />: -{deduction}%
                </ListItem>
              ))}
            </List>
          </Stack>
          <Stack gap={1}>
            <Typography fontWeight={700}>
              2.&nbsp;
              <FormattedMessage id="recipes.props.onbrand.explanation.content.ingredient_type.title" />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage id="recipes.props.onbrand.explanation.content.ingredient_type.text" />
            </Typography>

            {/* display ingredient list in 2 columns */}
            <Stack direction="row" gap={2}>
              <IngredientList ingredients={ingredients.odd} />
              <IngredientList ingredients={ingredients.even} />
            </Stack>
          </Stack>
          <Stack gap={1}>
            <Typography fontWeight={700}>
              3.&nbsp;
              <FormattedMessage id="recipes.props.onbrand.explanation.content.mep.title" />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage
                id="recipes.props.onbrand.explanation.content.mep.text"
                values={{ amount: rules?.mepRules?.[0]?.rangeMax }}
              />
            </Typography>
          </Stack>
          <Stack gap={1}>
            <Typography fontWeight={700}>
              4.&nbsp;
              <FormattedMessage id="recipes.props.onbrand.explanation.content.materials.title" />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage
                id="recipes.props.onbrand.explanation.content.materials.text"
                values={{ amount: rules?.allowedMaterialCount }}
              />
            </Typography>
          </Stack>
        </DialogContent>
      )}
    </Dialog>
  );
};

const IngredientList = ({ ingredients }: { ingredients: string[] }) => {
  return (
    <List sx={{ flex: 1, listStyle: 'disc' }} dense disablePadding>
      {ingredients.map((ingredient) => (
        <ListItem
          key={ingredient}
          disablePadding
          dense
          sx={(theme) => ({
            display: 'list-item',
            listStylePosition: 'inside',
            fontStyle: 'italic',
            ...theme.typography.caption,
          })}
        >
          {ingredient}
        </ListItem>
      ))}
    </List>
  );
};

export default OnBrandnessExplanationDialog;
