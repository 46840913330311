import { isProd } from 'utils/env.utils';

interface ENV_CONFIG {
  NAME: string;
  VERSION: string;
  API_BASE: string;
  API_SERVER_URL: string;
  S3_BASE: string;
  ANALYTICS_GOOGLE_TRACKING_ID?: string;
  POSTHOG_KEY?: string;
  POSTHOG_HOST?: string;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  DEFAULT_TRANSLATION_LOCALE: string;
  SENTRY: {
    dsn: string;
    environment?: string;
    release?: string;
  };
  COGNITO_CLIENT_ID: string;
  COGNITO_USER_POOL_ID: string;
  COGNITO_REGION: string;
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',

  // api
  API_BASE: process.env.REACT_APP_API_BASE || '',
  API_SERVER_URL: process.env.REACT_APP_API_SERVER_URL || '',
  S3_BASE: process.env.REACT_APP_S3_BASE || '',

  // analytics
  ANALYTICS_GOOGLE_TRACKING_ID: process.env.REACT_APP_ANALYTICS_GOOGLE_TRACKING_ID || '',
  POSTHOG_KEY: process.env.REACT_APP_POSTHOG_KEY || '',
  POSTHOG_HOST: process.env.REACT_APP_POSTHOG_HOST || '',

  // error tracking
  SENTRY: {
    dsn: 'https://d17529be14d33686bc48e25dab8f16e8@o190235.ingest.us.sentry.io/4507023107555328',
    environment: process.env.REACT_APP_BUILD_ENV,
    release: isProd()
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },

  // auth
  COGNITO_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID ?? '',
  COGNITO_USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? '',
  COGNITO_REGION: process.env.REACT_APP_COGNITO_REGION ?? '',

  // dev
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID ?? '',
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE ?? 'en-GB',
  DEFAULT_TRANSLATION_LOCALE: process.env.REACT_APP_DEFAULT_TRANSLATION_LOCALE ?? 'en',
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE ?? 'nl',
};

export default config;
