import { Skeleton, Stack } from '@mui/material';

const MeasurementItemSkeleton = () =>
  Array.from({ length: 7 }).map((_, idx) => (
    <Stack key={idx} direction="row" px={2} py={1.5} width="100%" alignItems="center">
      <Skeleton height={14} width={160} />
    </Stack>
  ));

export default MeasurementItemSkeleton;
