import { useMutation } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';

const translateRecipe = (recipeId: number) => {
  return apiService.translateRecipe(recipeId);
};
export const useTranslateRecipe = () => {
  const intl = useIntl();
  const notifications = useNotifications();

  const { mutate, isPending, error, isError } = useMutation({
    mutationFn: translateRecipe,
    onError: () => {
      notifications.error({
        message: intl.formatMessage({ id: 'recipes.translations.automatic.error' }),
      });
    },
  });
  return {
    translateRecipe: mutate,
    isPending,
    isError,
    error,
  };
};
