import { Skeleton, Stack, TableRow, Typography } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import {
  RecipeTranslationMethod,
  TranslatedPreparationStep,
} from 'types/recipe-translations.types';

import { Image } from 'components/@common';

import { TranslationField } from '../TranslationField';
import { TableCell, TranslationsTable } from '../TranslationsTable';

type Props = {
  targetLocale: string;
  isLoading?: boolean;
};

const PreparationTranslationsTable = ({
  targetLocale = DEFAULT_TRANSLATION_LOCALE,
  isLoading,
}: Props) => {
  const { setValue } = useFormContext();
  const preparationSteps = useWatch({
    name: `translation.preparationSteps`,
  }) as TranslatedPreparationStep[];
  const sortedPreparationSteps = preparationSteps?.sort((a, b) => a.order - b.order);

  return (
    <TranslationsTable
      isLoading={isLoading}
      targetLocale={targetLocale}
      prependColumn={{
        title: 'recipes.props.image',
        loadingContent: <Skeleton height={206} width={206} />,
      }}
    >
      {sortedPreparationSteps?.map((step, index) => (
        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell>
            <Image src={String(sortedPreparationSteps[index].image)} />
          </TableCell>
          <TableCell>
            <Stack gap={1}>
              <Typography variant="body1">{step.description}</Typography>
            </Stack>
          </TableCell>
          <TableCell>
            <TranslationField
              fieldName={`translation.preparationSteps[${index}].translation.description`}
              initialValue={step.translation.description}
              method={step.translation.fieldTranslationMethods.description}
              onChange={() =>
                setValue(
                  `translation.preparationSteps[${index}].translation.fieldTranslationMethods.description`,
                  RecipeTranslationMethod.Human,
                )
              }
            />
          </TableCell>
        </TableRow>
      ))}
    </TranslationsTable>
  );
};

export default PreparationTranslationsTable;
