import {
  autocompleteClasses,
  AutocompleteRenderInputParams,
  Box,
  filledInputClasses,
  InputAdornment,
  inputBaseClasses,
  TextField,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';

import { Country } from 'constants/countries.constants';
import { COUNTRIES_ALL, MEP_PREFIX } from 'constants/recipes.constants';
import { RecipeRequirement } from 'types/recipes.types';

import { CountryFlag } from 'components/@common';
import { CountrySelect } from 'components/@form';

type Props = AutocompleteRenderInputParams & {
  isMEP: boolean;
  isHQ: boolean;
  requirementType: RecipeRequirement;
  country: Country;
  isEditingTranslations: boolean;
};

const RecipeNameField = ({
  isMEP,
  isHQ,
  requirementType,
  country,
  isEditingTranslations,
  ...props
}: Props) => {
  const intl = useIntl();
  return (
    <TextField
      className="recipe-title"
      variant="filled"
      {...props}
      slotProps={{
        htmlInput: {
          ...props.inputProps,
          maxLength: 255,
          style: { textTransform: 'uppercase', padding: 0, height: 52 },
        },
        input: {
          ...props.InputProps,
          startAdornment: isMEP && (
            <InputAdornment position="start" sx={{ marginTop: '0 !important' }}>
              <Typography fontSize={32}>{MEP_PREFIX}</Typography>
            </InputAdornment>
          ),
          endAdornment: isEditingTranslations ? null : (
            <InputAdornment position="end">
              {isHQ && requirementType !== RecipeRequirement.Core ? (
                <CountrySelect />
              ) : (
                <Box px={1}>
                  <CountryFlag
                    country={requirementType === RecipeRequirement.Core ? COUNTRIES_ALL : country}
                    size={32}
                  />
                </Box>
              )}
            </InputAdornment>
          ),
        },
      }}
      sx={{
        [`& .${inputBaseClasses.root}`]: {
          paddingTop: 0,
          ':hover': { backgroundColor: 'black.4' },
        },
        '@media print': {
          [`& .${filledInputClasses.root}`]: { p: 0 },
          [`& .${autocompleteClasses.input}`]: {
            textOverflow: 'unset',
            whiteSpace: 'normal',
          },
        },
      }}
      placeholder={intl.formatMessage({ id: 'recipes.creator.input.title.label' })}
    />
  );
};

export default RecipeNameField;
