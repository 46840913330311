import { useMutation } from '@tanstack/react-query';

import { apiService } from 'services';
import { UpdateRecipePerformanceDataParams } from 'types/recipes.types';

const putPerformanceData = async (options: UpdateRecipePerformanceDataParams) => {
  const { data: performanceData } = await apiService.putPerformanceData({
    recipeId: options.recipeId,
    performanceData: options.performanceData,
  });
  return performanceData;
};

export const useUpdatePerformanceData = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: putPerformanceData,
  });

  return {
    updatePerformanceData: mutate,
    isPending,
  };
};
