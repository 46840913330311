import { useMutation, useQueryClient } from '@tanstack/react-query';
import { capitalize } from 'lodash';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { Menu } from 'types/menus.types';

const deleteMenu = async (id: Menu['id']) => {
  const res = await apiService.deleteMenu(id);
  return res;
};

export const useDeleteMenu = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: deleteMenu,
    onSuccess: (_, id) => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: QueryKeys.menus.all() });
        queryClient.removeQueries({ queryKey: QueryKeys.menus.byId(id) });
      }, 100);

      notifications.success({
        message: intl.formatMessage(
          { id: 'general.actions.delete.success' },
          {
            type: capitalize(intl.formatMessage({ id: 'menus.label.singular' })),
          },
        ),
      });
    },
    onError: () => {
      notifications.error({
        message: intl.formatMessage(
          { id: 'general.actions.delete.error' },
          {
            type: capitalize(intl.formatMessage({ id: 'menus.label.singular' })),
          },
        ),
      });
    },
  });

  return {
    deleteMenu: mutate,
    isPending,
  };
};
