import { useMutation, useQueryClient } from '@tanstack/react-query';
import { capitalize } from 'lodash';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { useCheckForDuplicateRecipeName } from 'queries/recipes/hooks';
import { apiService } from 'services';
import { useNotifications } from 'services/snackbar';
import { UpdateRecipeParams } from 'types/recipes.types';

const putRecipe = async (data: UpdateRecipeParams & { silent: boolean }) => {
  const { data: recipe } = await apiService.putRecipe(data);
  return recipe;
};

export const useUpdateRecipe = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();
  const checkError = useCheckForDuplicateRecipeName();

  const { mutate, isPending, isSuccess, isError } = useMutation({
    mutationFn: putRecipe,
    onSuccess: ({ data: { id } }, { silent }) => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.recipes.all() });
      queryClient.invalidateQueries({ queryKey: QueryKeys.recipes.byId(id) });

      if (!silent) {
        notifications.success({
          message: intl.formatMessage(
            { id: 'general.actions.save.success' },
            {
              type: capitalize(intl.formatMessage({ id: 'recipes.label.singular' })),
            },
          ),
        });
      }
    },
    onError: (error, { silent }) => {
      const isDuplicate = checkError(error);

      if (!silent) {
        if (!isDuplicate) {
          notifications.error({
            message: intl.formatMessage(
              { id: 'general.actions.save.error' },
              {
                type: capitalize(intl.formatMessage({ id: 'recipes.label.singular' })),
              },
            ),
          });
        }
      }
    },
  });

  return {
    updateRecipe: mutate,
    isPending,
    isSuccess,
    isError,
  };
};
