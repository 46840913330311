import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { ApiResponse } from 'services/api/api.types';
import { useNotifications } from 'services/snackbar';
import { Menu, MenuItem } from 'types/menus.types';

const putMenuItems = async (data: MenuItem[]) => {
  const { data: menuItem } = await apiService.putMenuItems(data);
  return menuItem;
};

export const useBulkUpdateMenuItem = () => {
  const intl = useIntl();
  const notifications = useNotifications();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: putMenuItems,
    onMutate: async (updatedItems) => {
      const menuId = updatedItems[0]?.menuId;

      // Cancel any outgoing refetches so they don't overwrite our optimistic update
      await queryClient.cancelQueries({ queryKey: QueryKeys.menus.byId(menuId) });

      const queryData = queryClient.getQueriesData<ApiResponse<Menu>>({
        queryKey: QueryKeys.menus.byId(menuId),
        exact: true,
      });
      queryData.forEach(([key, data]) => {
        if (!!data) {
          queryClient.setQueryData<ApiResponse<Menu>>(key, {
            ...data,
            data: {
              ...data.data,
              menuItems: data.data.menuItems?.map((menuItem) => {
                const updatedMenuItem = updatedItems.find((item) => item.id === menuItem.id);
                return updatedMenuItem || menuItem;
              }),
            },
          });
        }
      });

      return { previousData: queryData };
    },
    onSuccess: async () => {
      notifications.success({
        message: intl.formatMessage(
          { id: 'general.actions.save.success' },
          { type: intl.formatMessage({ id: 'menus.items.label' }) },
        ),
      });
    },
    onError: (_err, _, context) => {
      notifications.error({
        message: intl.formatMessage(
          { id: 'general.actions.save.error' },
          { type: intl.formatMessage({ id: 'menus.items.label' }) },
        ),
      });

      if (context?.previousData) {
        context.previousData.forEach(([key, data]) => {
          if (!!data) {
            queryClient.setQueryData(key, data);
          }
        });
      }
    },
  });

  return {
    updateMenuItems: mutate,
    isPending,
  };
};
