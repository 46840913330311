import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { svgIconClasses } from '@mui/material';
import { ReactNode } from 'react';

import { Tooltip } from 'components/@common/Tooltip';

type Props = LoadingButtonProps & {
  tooltip: ReactNode;
  icon: ReactNode;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  loading?: boolean;
};

const ChatTooltipButton = ({
  icon,
  tooltip,
  tooltipPlacement = 'bottom',
  loading,
  ...restProps
}: Props) => {
  return (
    <Tooltip placement={tooltipPlacement} title={tooltip} offset={[0, -4]} zIndex={10001}>
      <LoadingButton
        loading={loading}
        sx={{
          width: 32,
          height: 32,
          p: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 0.5,
          bgcolor: 'primary.main',
          transition: 'all 0.2s ease',
          '&:hover': { bgcolor: 'primary.dark' },
          '&:active': { bgcolor: 'primary.darker' },
          '&:disabled': { bgcolor: 'primary.main', opacity: 0.3 },
          [`& .${svgIconClasses.root}`]: {
            width: 24,
            height: 24,
            color: 'global01.main',
          },
        }}
        {...restProps}
      >
        {icon}
      </LoadingButton>
    </Tooltip>
  );
};

export default ChatTooltipButton;
