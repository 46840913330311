import { Add as AddIcon } from '@mui/icons-material';
import { FilledTextFieldProps, LinearProgress, Stack, Typography } from '@mui/material';
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatBytes } from 'utils/file.utils';

import { DROPZONE_ACCEPTED_FILETYPES, DROPZONE_MAX_FILESIZE } from 'constants/forms.constants';
import { useUploadMedia } from 'queries/media/useUploadMedia';
import { UploadMediaOptions } from 'types/media.types';

import { Tooltip } from 'components/@common/Tooltip';

import { UploadedMedia } from './UploadedMedia';

export const DEFAULT_DROPZONE_HEIGHT = 204;

type Props = Pick<UseControllerProps, 'rules' | 'name'> &
  Omit<FilledTextFieldProps, 'variant' | 'name'> & {
    hideError?: boolean;
    storageContext: UploadMediaOptions['context'];
    storageFolder: UploadMediaOptions['storageFolder'];
    bgcolor?: string;
    height?: number;
    testId?: string;
  };

const DropZone = forwardRef(
  (
    {
      rules,
      name,
      disabled,
      storageContext,
      storageFolder,
      bgcolor = 'transparent',
      height = DEFAULT_DROPZONE_HEIGHT,
      testId,
    }: Props,
    ref,
  ) => {
    const intl = useIntl();
    const { control, setValue, setError, clearErrors } = useFormContext();
    const { uploadMedia } = useUploadMedia();

    const [isUploading, setIsUploading] = useState<boolean>(false);

    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        if (disabled) return;

        setValue(name, '', { shouldDirty: true });
        clearErrors(name);

        setIsUploading(true);
        uploadMedia(
          {
            file: acceptedFiles[0],
            options: { context: storageContext, storageFolder },
          },
          {
            onSuccess: (res) => setValue(name, res.imageUrl, { shouldDirty: true }),
            onSettled: () => setIsUploading(false),
          },
        );
      },
      [clearErrors, name, setValue, storageContext, storageFolder, uploadMedia, disabled],
    );

    useImperativeHandle(ref, () => ({ addFiles: onDrop }), [onDrop]);

    const [isHovering, setIsHovering] = useState<boolean>(false);

    const handlePaste = useCallback(
      (event: React.ClipboardEvent<HTMLDivElement>) => {
        if (disabled) return;

        const file = event.clipboardData?.files?.item(0);
        if (!file) return;

        event.preventDefault();
        event.stopPropagation();
        onDrop([file]);
      },
      [disabled, onDrop],
    );

    useEffect(() => {
      const checkPasteEvent = (event: ClipboardEvent) => {
        if (isHovering) {
          event.preventDefault();
          handlePaste(event as unknown as React.ClipboardEvent<HTMLDivElement>);
        }
      };

      document.addEventListener('paste', checkPasteEvent);
      return () => document.removeEventListener('paste', checkPasteEvent);
    }, [handlePaste, isHovering]);

    const onDropRejected = useCallback(
      (rejectedFiles: FileRejection[]) => {
        if (
          rejectedFiles.some((file) =>
            file.errors.some((error) => error.code === ErrorCode.FileTooLarge),
          )
        ) {
          setError(name, {
            type: 'max',
            message: intl.formatMessage(
              { id: 'form.validation.max_size' },
              { size: formatBytes(DROPZONE_MAX_FILESIZE) },
            ),
          });
        }
      },
      [name, intl, setError],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: DROPZONE_ACCEPTED_FILETYPES,
      maxSize: DROPZONE_MAX_FILESIZE,
      multiple: false,
      disabled,
      onDrop,
      onDropRejected,
    });

    return (
      <Controller
        rules={rules}
        name={name}
        control={control}
        render={({ field: { name, value } }) => {
          return (
            <Tooltip
              title={disabled ? '' : <FormattedMessage id="form.dropzone.hover_tooltip" />}
              placement="top"
            >
              <Stack
                bgcolor="bg.paper"
                direction="row"
                justifyContent="center"
                alignItems="center"
                height="fit-content"
                sx={(theme) => ({
                  bgcolor,
                  cursor: disabled ? 'not-allowed' : 'pointer',
                  transition: 'all 0.2s ease-in',
                  borderRadius: theme.mixins.borderRadius.base,
                  ...(isDragActive && {
                    bgcolor: disabled ? undefined : 'primary.lighter',
                  }),
                  '&:hover, &:active': {
                    bgcolor: disabled ? undefined : 'black.20',
                  },
                })}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                {...getRootProps()}
              >
                <input
                  type="file"
                  name={name}
                  {...getInputProps()}
                  data-testid={`${testId}-upload-input`}
                />

                {isUploading && (
                  <Stack
                    sx={{ cursor: 'default' }}
                    height={height}
                    gap={1}
                    alignItems="center"
                    justifyContent="center"
                    data-testid={`${testId}-uploading`}
                  >
                    <Typography variant="body2">
                      <FormattedMessage id="form.dropzone.uploading" />
                    </Typography>
                    <LinearProgress sx={{ width: '100%' }} variant="indeterminate" />
                  </Stack>
                )}
                {!isUploading && !!value && (
                  <UploadedMedia
                    data-testid={`${testId}-uploaded`}
                    handleRemove={() => setValue(name, '', { shouldDirty: true })}
                    url={value}
                    isDragActive={isDragActive}
                    height={height}
                    disabled={disabled}
                  />
                )}
                {!disabled && !isUploading && !value && (
                  <Stack
                    data-testid={`${testId}-upload-img`}
                    className="no-print"
                    flex={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                    height={height}
                    pl={0.5}
                    pr={1}
                  >
                    <AddIcon sx={{ color: 'black.60' }} />
                    <Stack direction="column">
                      <Typography
                        variant="chip"
                        color={disabled ? 'text.disabled' : 'text-primary'}
                      >
                        <FormattedMessage id="form.dropzone.add_photo" />
                      </Typography>
                      <Typography
                        sx={{ color: (theme) => theme.palette.text.disabled }}
                        variant="tooltip"
                      >
                        <FormattedMessage id="form.dropzone.select_file" />
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Tooltip>
          );
        }}
      />
    );
  },
);

DropZone.displayName = 'DropZone';

export default memo(DropZone);
