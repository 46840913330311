import {
  Check as CheckIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  RemoveCircle as RemoveCircleIcon,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { ButtonWithMenu } from 'components/@common/ButtonWithMenu';

type Props = {
  isHQ: boolean;
  onReject: () => void;
  onApprove: () => void;
  onPublish: () => void;
  isLoading: boolean;
};

const RecipeSubmittedButton = ({ isHQ, onReject, onApprove, onPublish, isLoading }: Props) => {
  if (isHQ) {
    return (
      <ButtonWithMenu
        buttonText={<FormattedMessage id="recipes.creator.button.hq.submitted" />}
        testId="submitted-recipe-menu-button"
        color="secondary"
        onClick={onApprove}
        loading={isLoading}
        startIcon={<CheckIcon />}
        menuItems={[
          {
            label: <FormattedMessage id="recipes.creator.button.hq.approve_publish" />,
            icon: <PlaylistAddCheckIcon />,
            onClick: onPublish,
            action: 'approve-publish',
          },
          {
            label: <FormattedMessage id="recipes.creator.button.hq.request_changes" />,
            icon: <RemoveCircleIcon />,
            onClick: onReject,
            action: 'request-changes',
          },
        ]}
      />
    );
  }
  return (
    <Button variant="contained" disabled data-testid="submitted-recipe-button">
      <FormattedMessage id="recipes.creator.button.franchisee.submitted" />
    </Button>
  );
};

export default RecipeSubmittedButton;
