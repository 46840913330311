import { ArrowForwardRounded as ArrowForwardRoundedIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, Collapse, Rating, Stack } from '@mui/material';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { Country } from 'constants/countries.constants';
import { DEFAULT_TRANSLATION_LOCALE } from 'constants/recipe-translations.constants';
import { COUNTRIES_ALL } from 'constants/recipes.constants';
import { Routes } from 'constants/routes.constants';
import { useLanguages } from 'queries';
import { SaveOptions } from 'types/common.types';
import {
  RecipeTranslationMethod,
  RecipeTranslationsResponse,
  Translation,
} from 'types/recipe-translations.types';
import { RecipeFormValues, RecipeRequirement } from 'types/recipes.types';

import { CountryFlag } from 'components/@common';
import { Tooltip } from 'components/@common/Tooltip';
import { useIsInMenuItemDrawer } from 'components/@menu-form';
import { RecipeNameAutocomplete } from 'components/@recipe-form/RecipeNameAutocomplete';
import { TranslationField, TranslationStatus } from 'components/@recipe-translations';
import { TranslationSelector } from 'components/@recipe-translations/TranslationSelector';

import {
  RecipeActions,
  RecipeCategory,
  RecipeCountry,
  RecipeRequirementType,
  RecipeSeasons,
  RecipeType,
} from './components';
import { TitleBox } from './RecipeFormMainInfo.style';

type Props = {
  isReadOnly: boolean;
  isSaving: boolean;
  disableActions: boolean | undefined;
  onSave: (options: SaveOptions) => void;
  onEditRecipe: () => void;
  onStopEditRecipe: () => void;
  calculateOnBrandness: (successCallback?: () => void) => void;
  recipeTranslations?: Translation[];
  onEditTranslations: () => void;
  isEditingTranslations: boolean;
  isLoadingTranslations: boolean;
  targetTranslationLocale: string;
};

const RecipeFormMainInfo = ({
  isSaving,
  isReadOnly,
  disableActions,
  onSave,
  onEditRecipe,
  onStopEditRecipe,
  calculateOnBrandness,
  recipeTranslations = [],
  onEditTranslations,
  isEditingTranslations,
  isLoadingTranslations,
  targetTranslationLocale,
}: Props) => {
  const { languages } = useLanguages();
  const isInMenuItemDrawer = useIsInMenuItemDrawer();
  const { setValue, getValues } = useFormContext<RecipeFormValues>();
  const recipeId = useWatch<RecipeFormValues>({ name: 'id' }) as RecipeFormValues['id'];
  const country = useWatch<RecipeFormValues>({ name: 'country' }) as Country;
  const requirementType = useWatch<RecipeFormValues>({
    name: 'requirementType',
  }) as RecipeRequirement;
  const translation = useWatch<RecipeFormValues>({
    name: 'translation',
  }) as RecipeFormValues['translation'];

  const openRecipe = () => {
    window.open(generatePath(Routes.RecipeDetail, { recipeId: String(recipeId) }), '_blank');
  };

  const translationLocale = useWatch({
    name: 'translation.language',
  }) as RecipeTranslationsResponse['language'];
  const isShowingTranslation = targetTranslationLocale === translationLocale;

  return (
    <Stack width="100%" height="fit-content">
      {isInMenuItemDrawer && (
        <Stack mb={3} direction="row" alignItems="center" justifyContent="space-between">
          <Rating />
          <CountryFlag
            country={requirementType === RecipeRequirement.Core ? COUNTRIES_ALL : country}
            size={32}
          />
        </Stack>
      )}

      <Collapse in={!isEditingTranslations}>
        <RecipeRequirementType isReadOnly={isReadOnly} />
      </Collapse>

      <Stack mb={4}>
        <TitleBox>
          <Stack
            direction="row"
            gap={2}
            alignItems="center"
            flex={isReadOnly && !isEditingTranslations ? undefined : 1}
          >
            {isEditingTranslations && (
              <TranslationField
                fieldName="translation.translation.name"
                initialValue={String(translation?.translation.name)}
                isTitle
                method={
                  translation?.translation.fieldTranslationMethods.name ||
                  RecipeTranslationMethod.AI
                }
                onChange={() =>
                  setValue(
                    'translation.translation.fieldTranslationMethods.name',
                    RecipeTranslationMethod.Human,
                  )
                }
              />
            )}

            {!isEditingTranslations && (
              <RecipeNameAutocomplete
                isReadOnly={isReadOnly}
                onSave={onSave}
                isEditingTranslations={isEditingTranslations}
                isShowingTranslations={isShowingTranslation}
                isLoadingTranslations={isLoadingTranslations}
              />
            )}

            {!isInMenuItemDrawer && (
              <Box mt={1}>
                <Tooltip
                  title={<FormattedMessage id="recipes.translations.translate_on_submit.tooltip" />}
                  maxWidth={200}
                  disabled={
                    !!recipeTranslations.length ||
                    (!recipeTranslations.length && isReadOnly) ||
                    isEditingTranslations
                  }
                >
                  <TranslationSelector
                    prefix={
                      !!recipeTranslations.length &&
                      recipeTranslations.length !== languages.length - 1 && (
                        <CircularProgress size={16} color="inherit" sx={{ ml: 1 }} />
                      )
                    }
                    menuItemSuffix={(lang, status) => (
                      <TranslationStatus
                        verifiedStatus={status}
                        aiTooltip="recipes.translations.ai.tooltip"
                        language={lang}
                      />
                    )}
                    suffix={(lang, status) => {
                      if (!!recipeTranslations.length && lang !== DEFAULT_TRANSLATION_LOCALE) {
                        return (
                          <TranslationStatus
                            verifiedStatus={status}
                            aiTooltip="recipes.translations.ai.tooltip"
                            language={lang}
                          />
                        );
                      }
                    }}
                    recipeTranslations={recipeTranslations}
                    disabled={!recipeTranslations.length || !isReadOnly}
                  />
                </Tooltip>
              </Box>
            )}
          </Stack>

          {!disableActions && (
            <RecipeActions
              recipeTranslations={recipeTranslations}
              isReadOnly={isReadOnly}
              isSaving={isSaving}
              onStopEditRecipe={onStopEditRecipe}
              onEditRecipe={onEditRecipe}
              saveRecipe={onSave}
              calculateOnBrandness={calculateOnBrandness}
              isEditingTranslations={isEditingTranslations}
              onEditTranslations={onEditTranslations}
            />
          )}
        </TitleBox>
        {!isShowingTranslation && <RecipeCountry recipe={getValues()} isReadOnly={isReadOnly} />}
      </Stack>

      <Collapse in={!isEditingTranslations}>
        <Stack direction="row" alignItems="center" gap={1} flexWrap="wrap" mt={0.5}>
          <RecipeSeasons isReadOnly={isReadOnly} />
          <RecipeCategory isReadOnly={isReadOnly} />
          <RecipeType isReadOnly={isReadOnly} />

          {isInMenuItemDrawer && (
            <Box ml="auto">
              <Button
                endIcon={<ArrowForwardRoundedIcon />}
                variant="text"
                sx={(theme) => ({ color: theme.palette.global01.main })}
                onClick={openRecipe}
              >
                <FormattedMessage id="recipes.view_full" />
              </Button>
            </Box>
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default memo(RecipeFormMainInfo);
