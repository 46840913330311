import { Autocomplete, Chip, TextField } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import MenuExportSection from './MenuExportSection';

type Props = {
  emails: string[];
  onChange: (emails: string[]) => void;
};

const MenuExportEmails = ({ emails, onChange }: Props) => {
  const intl = useIntl();
  return (
    <MenuExportSection
      title="menus.export.dialog.email.title"
      description="menus.export.dialog.email.description"
      index={1}
    >
      <Autocomplete
        multiple
        id="tags-filled"
        options={[]}
        value={emails}
        onChange={(_, newValue) => onChange(newValue)}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} key={option} />
          ))
        }
        renderInput={(params) => {
          const clearText = () =>
            params.inputProps?.onChange?.({
              target: { value: '' },
            } as unknown as React.ChangeEvent<HTMLInputElement>);

          const addEmail = (email: string) => {
            if (!email) return;
            onChange([...emails, email]);
            clearText();
          };

          const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
            params.inputProps?.onBlur?.(event);
            addEmail(event.target.value);
          };

          const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === ',' || event.key === ' ') {
              event.preventDefault();
              addEmail((event.target as unknown as { value: string }).value);
            }
          };

          return (
            <TextField
              {...params}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              placeholder={intl.formatMessage({ id: 'menus.export.dialog.placeholder' })}
            />
          );
        }}
      />
    </MenuExportSection>
  );
};

export default MenuExportEmails;
