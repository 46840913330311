import { WarningRounded as WarningRoundedIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { FormattedMessage } from 'react-intl';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  translationsAmount?: number;
};

const RecipeOverrideDialog = ({ isOpen, onConfirm, onClose, translationsAmount }: Props) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'up' } as TransitionProps}
      PaperProps={{
        sx: { margin: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
      }}
      sx={{
        '& .MuiDialog-container': {
          height: 'fit-content',
          bottom: 0,
          position: 'fixed',
          width: '100%',
        },
      }}
    >
      <DialogTitle display="flex" justifyContent="space-between" alignItems="center" gap={4}>
        <Stack direction="row" gap={1} alignItems="center">
          <WarningRoundedIcon sx={{ fill: 'orange' }} />
          <FormattedMessage id="recipes.creator.override_translations.title" />
        </Stack>
        <Button variant="text" color="inherit" onClick={onClose}>
          <FormattedMessage id="common.close" />
        </Button>
      </DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="body1" color="black.60">
          <FormattedMessage
            id="recipes.creator.override_translations.warning"
            values={{ amount: translationsAmount || 0 }}
          />
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" color="inherit">
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button onClick={handleConfirm} variant="contained">
          <FormattedMessage id="common.proceed" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecipeOverrideDialog;
