import { AutocompleteRenderOptionState, MenuItem, Typography } from '@mui/material';
import { HTMLAttributes } from 'react';
import { InView } from 'react-intersection-observer';

import { highlightText } from 'utils/text.utils';

import { Measurement } from 'types/recipes.types';

type Props = Omit<HTMLAttributes<HTMLLIElement>, 'onChange'> & {
  key: string;
  measurement: Measurement;
  onGetNext: (inView: boolean) => void;
  totalMeasurements: number;
  state: AutocompleteRenderOptionState;
  onChange: (measurement: Measurement | string | null) => void;
};

const MeasurementItem = ({
  measurement,
  onGetNext,
  totalMeasurements,
  state,
  onChange,
  ...props
}: Props) => {
  return (
    <>
      <MenuItem
        {...props}
        data-testid={measurement.unit}
        sx={{
          px: 2,
          py: 1.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          gap: 1,
        }}
        onClick={() => onChange(measurement)}
      >
        <Typography overflow="clip" textOverflow="ellipsis" width="100%">
          {highlightText(state.inputValue, measurement?.unit || '')}
        </Typography>
      </MenuItem>
      {state.index === totalMeasurements - 1 && <InView as="div" onChange={onGetNext} />}
    </>
  );
};

export default MeasurementItem;
