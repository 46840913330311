import { Box, Card, Slider, sliderClasses, Stack, Typography } from '@mui/material';
import { formatRelative } from 'date-fns';
import { debounce, noop } from 'lodash';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useMedia } from 'hooks';
import { useIsHQ } from 'hooks/useIsHQ';
import { useUpdatePerformanceData, useUser } from 'queries';
import { useNotifications } from 'services/snackbar';
import {
  RecipeFormValues,
  RecipePerformanceData as RecipePerformanceDataType,
} from 'types/recipes.types';

import { RatingSelect } from 'components/@form';

type Props = {
  disableActions?: boolean;
};

const RecipePerformanceData = ({ disableActions }: Props) => {
  const intl = useIntl();
  const notifications = useNotifications();
  const isHQ = useIsHQ();
  const { user } = useUser();
  const recipeCountry = useWatch<RecipeFormValues>({ name: 'country' });
  const recipeId = useWatch<RecipeFormValues>({ name: 'id' });
  const { sm } = useMedia();

  const recipeFormMethods = useFormContext<RecipeFormValues>();
  const [updatedAt, setUpdatedAt] = useState<string | Date | null>(
    recipeFormMethods.getValues('performanceData').updatedAt,
  );
  const performanceForm = useForm<RecipePerformanceDataType>({
    defaultValues: recipeFormMethods.getValues('performanceData') as RecipePerformanceDataType,
  });

  const isReadOnly = disableActions || (!isHQ && recipeCountry !== user?.group.country);

  const { updatePerformanceData, isPending } = useUpdatePerformanceData();
  const handleSave = useCallback(() => {
    performanceForm.handleSubmit(
      () => {
        updatePerformanceData(
          {
            recipeId: Number(recipeId),
            performanceData: performanceForm.getValues(),
          },
          {
            onSuccess: () => {
              setUpdatedAt(new Date());
              performanceForm.clearErrors();
              performanceForm.reset(performanceForm.getValues());
            },
          },
        );
      },
      () =>
        notifications.error({
          message: intl.formatMessage(
            { id: 'general.actions.save.error' },
            { type: intl.formatMessage({ id: 'menus.label.singular' }) },
          ),
        }),
    )();
  }, [intl, performanceForm, notifications, recipeId, updatePerformanceData]);

  const handleSaveDebounced = useCallback(
    (handleSave: () => void) => debounce(handleSave, 1000)(),
    [],
  );

  useEffect(() => {
    if (performanceForm.formState.isDirty && !isPending) {
      handleSaveDebounced(handleSave);
    }
  }, [isPending, handleSave, handleSaveDebounced, performanceForm.formState.isDirty]);

  return (
    <Box component="form">
      <Card
        elevation={0}
        sx={{
          p: 2,
          gap: 1.75,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'visible',
          height: '100%',
          '@media print': { gap: 0 },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography sx={{ color: (theme) => theme.palette.global01.main }}>
            <FormattedMessage id="recipes.props.performance_data" />
          </Typography>

          {updatedAt && (
            <Typography variant="caption" sx={{ color: (theme) => theme.palette.global01.main }}>
              <FormattedMessage
                id="recipes.props.performance_data.last_updated"
                values={{ date: formatRelative(updatedAt, new Date()) }}
              />
            </Typography>
          )}
        </Stack>

        <Stack
          direction="row"
          gap={1.5}
          flexWrap="wrap"
          sx={{ '@media print': { flexDirection: 'column' } }}
        >
          <PerformanceItem
            label={<FormattedMessage id="recipes.props.performance.food_cost" />}
            compactView={disableActions || sm}
          >
            <Controller
              control={performanceForm.control}
              name="foodCost"
              render={({ field: { ref, ...field }, fieldState, formState }) => (
                <PerformanceSlider
                  {...field}
                  {...fieldState}
                  {...formState}
                  max={100}
                  step={10}
                  labelAfter="%"
                  readOnly={isReadOnly}
                />
              )}
            />
          </PerformanceItem>
          <PerformanceItem
            label={<FormattedMessage id="recipes.props.performance.units_sold" />}
            compactView={disableActions || sm}
          >
            <Controller
              control={performanceForm.control}
              name="unitsSold"
              render={({ field: { ref, ...field }, fieldState, formState }) => (
                <PerformanceSlider
                  {...field}
                  {...fieldState}
                  {...formState}
                  max={10}
                  step={1}
                  readOnly={isReadOnly}
                  labelAfter={field.value >= 10 ? '+' : ''}
                />
              )}
            />
          </PerformanceItem>
          <PerformanceItem
            label={<FormattedMessage id="recipes.props.performance.execution" />}
            compactView={disableActions || sm}
          >
            <Controller
              control={performanceForm.control}
              name="easyToExecute"
              render={({ field: { ref, ...field }, fieldState, formState }) => (
                <PerformanceSlider
                  {...field}
                  {...fieldState}
                  {...formState}
                  max={5}
                  step={1}
                  readOnly={isReadOnly}
                />
              )}
            />
          </PerformanceItem>
          <PerformanceItem
            label={<FormattedMessage id="recipes.props.performance.feedback" />}
            compactView={disableActions || sm}
          >
            <Controller
              control={performanceForm.control}
              name="customerFeedback"
              render={({ field: { ref, ...field }, fieldState, formState }) => (
                <RatingSelect {...field} {...fieldState} {...formState} readOnly={isReadOnly} />
              )}
            />
          </PerformanceItem>
        </Stack>
      </Card>
    </Box>
  );
};

export default RecipePerformanceData;

type PerformanceItemProps = {
  label: ReactNode;
  children: ReactNode;
  compactView?: boolean;
};
const PerformanceItem = ({ label, children, compactView }: PerformanceItemProps) => (
  <Card
    variant="outlined"
    sx={(theme) => ({
      pt: 1.25,
      pb: 1,
      px: 1.5,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minWidth: compactView ? 204 : 0,
      width: compactView ? 'fit-content' : '100%',
      flex: compactView ? 'auto' : 1,
      bgcolor: 'transparent',
      borderRadius: theme.mixins.borderRadius.base,
      '@media print': {
        border: 'none',
        flexDirection: 'row',
        alignItems: 'center',
        width: 280,
        gap: 4,
        p: 0,
      },
    })}
  >
    <Typography
      variant="label"
      sx={{
        color: (theme) => theme.palette.black[60],
        '@media print': { textTransform: 'none', lineHeight: '1.5rem' },
      }}
    >
      {label}
    </Typography>
    {children}
  </Card>
);

type PerformanceSliderProps = {
  value: number;
  max: number;
  step: number;
  onChange: (event: Event, value: number | number[], activeThumb: number) => void;
  labelAfter?: string;
  readOnly?: boolean;
};
const PerformanceSlider = ({
  value,
  max,
  step,
  labelAfter,
  onChange,
  readOnly,
}: PerformanceSliderProps) => {
  return (
    <Slider
      color="success"
      defaultValue={0}
      value={value || 0}
      min={0}
      max={max}
      step={step}
      onChange={readOnly ? noop : onChange}
      shiftStep={step}
      marks
      disabled={readOnly}
      valueLabelDisplay="on"
      size="small"
      valueLabelFormat={(value) => (labelAfter ? `${value}${labelAfter}` : value)}
      sx={(theme) => ({
        mx: 2,
        width: 'auto',
        [`& .${sliderClasses.thumb}`]: {
          width: labelAfter ? 42 : 22,
          height: 22,
          borderRadius: theme.mixins.borderRadius.full,
          '&.Mui-disabled': {
            bgcolor: `${theme.palette.success.main} !important`,
          },
        },
        [`& .${sliderClasses.rail}`]: {
          bgcolor: 'bluegrey.100',
        },
        [`& .${sliderClasses.mark}`]: {
          bgcolor: 'bluegrey.400',
          height: 2,
          width: 2,
          borderRadius: theme.mixins.borderRadius.full,
        },
        [`& .${sliderClasses.valueLabelLabel}`]: {
          ...theme.typography.subtitle2,
          color: 'text.secondary',
        },
        [`& .${sliderClasses.valueLabelOpen}`]: {
          transform: 'translateY(-50%) !important',
          top: '50%',
        },
        [`& .${sliderClasses.valueLabel}`]: {
          py: 0,
          px: labelAfter ? 0.5 : 0,
          borderRadius: theme.mixins.borderRadius.full,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          '&::before': {
            display: 'none',
          },
        },
      })}
    />
  );
};
