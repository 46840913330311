import {
  Box,
  Chip,
  chipClasses,
  CircularProgress,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { formatRelative } from 'date-fns';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';

import { lineClamp } from 'utils/text.utils';

import { seasonsMap } from 'constants/common.constants';
import { Routes } from 'constants/routes.constants';
import { Menu, MenuProcessingStatus, MenuStatus, MenuTableHeader } from 'types/menus.types';

import { CountryFlag } from 'components/@common';
import { useMenuProcessingStatus } from 'components/@menu-form/MenuForm/hooks';

type Props = {
  menu: Menu;
  tableHeaders: MenuTableHeader[];
};

const MenuTableRow = ({
  tableHeaders,
  menu: {
    id,
    name,
    // status,
    seasons,
    // isApproved,
    year,
    coveragePercentage,
    country,
    processingStatus: initialProcessingStatus,
    updatedAt,
    status,
  },
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleMenuClick = () => {
    navigate(generatePath(Routes.MenuDetail, { menuId: `${id}` }));
  };

  const processingStatus = useMenuProcessingStatus({
    menuId: id,
    initialStatus: initialProcessingStatus,
    refetchInterval: 5_000,
  });

  if (processingStatus === MenuProcessingStatus.Processing) {
    return (
      <TableRow hover onClick={handleMenuClick} sx={{ cursor: 'pointer', display: 'flex' }}>
        <TableCell
          colSpan={tableHeaders.length}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flex: 1,
            pr: 5,
            py: 4,
          }}
        >
          <Typography sx={{ color: (theme) => theme.palette.black[50] }}>
            {name ? name : <FormattedMessage id="menus.label.untitled" />}
          </Typography>

          <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
            <CircularProgress size={21} color="inherit" thickness={5} sx={{ opacity: 0.6 }} />
            <Typography variant="body1" sx={{ color: (theme) => theme.palette.black[60] }}>
              <FormattedMessage id="menus.scanning.in_progress" />
            </Typography>
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <TableCell
        sx={{
          py: 4,
          minWidth: 24,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ ...lineClamp(2), mr: 1 }}>
          {!name ? <FormattedMessage id="menus.label.untitled" /> : name}
        </Typography>
        {status === MenuStatus.Active && (
          <Chip
            variant="filled"
            color="success"
            label={<FormattedMessage id="menus.active.label" />}
            sx={(theme) => ({
              bgcolor: 'green.600',
              color: 'text.secondary',
              border: `none`,
              height: '1.5rem',
              ...theme.typography.buttonSmall,
              textTransform: 'none',
              [`& .${chipClasses.label}`]: {
                px: 1,
              },
            })}
          />
        )}
      </TableCell>
      <TableCell sx={{ justifyContent: 'center' }}>
        <Box display="flex" justifyContent="center">
          <CountryFlag country={country} size={32} />
        </Box>
      </TableCell>
      <TableCell sx={{ justifyContent: 'center' }}>
        <Typography variant="chip">{year}</Typography>
      </TableCell>
      <TableCell>
        <Chip
          variant="label"
          label={
            !seasons ? (
              <FormattedMessage id="general.seasons.none" />
            ) : seasons.length === 4 ? (
              <FormattedMessage id="general.seasons.all" />
            ) : (
              seasons.map((season) => intl.formatMessage({ id: seasonsMap[season] })).join(', ')
            )
          }
        />
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Chip
          variant="labelDark"
          sx={{ bgcolor: coveragePercentage < 50 ? 'orange.500' : 'green.600' }}
          label={`${coveragePercentage || 0}%`}
        />
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
        {formatRelative(updatedAt, new Date())}
      </TableCell>
    </>
  );
};

export default memo(MenuTableRow);
