import { LoadingButton } from '@mui/lab';
import { Box, styled } from '@mui/material';

export const SaveButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.white[100],
  color: theme.palette.info.main,
  boxShadow: theme.shadows[2],
  '&:hover, :focus': {
    backgroundColor: theme.palette.grey[100],
  },
  whiteSpace: 'nowrap',
  paddingBlock: theme.spacing(1),
  paddingInline: theme.spacing(3),
}));

export const TitleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
})) as typeof Box;
